<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title slot="start">
      <ion-text color="medium"> </ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="content-infocard">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <!-- <ion-img src="assets/img/running.png"></ion-img> -->

          <ion-text color="dark">
            <p>
              Disfruta de <ion-text color="tertiary">atención médica las 24 horas</ion-text>. Todos los días. Con
              consultas inmediatas por teléfono o video.vida saludable. Estamos para guiarte y apoyarte en tu viaje
              hacia un bienestar integral.
            </p>
            <p>
              Nuestros especialistas están disponibles para apoyarte con horarios extendidos durante la semana y los
              fines de semana.
            </p>
          </ion-text>
        </ion-card>
        <ion-button expand="block" color="tertiary" size="large" shape="round" [href]="'tel:' + jrContact">
          Iniciar Videoconsulta
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
