<ion-grid fixed class="ion-no-padding">
  <ion-row>
    <ion-col size="12">
      <ion-item lines="none">
        <ion-label position="stacked">Teléfono actual</ion-label>
        <ion-input type="tel" [formControl]="phoneNumber"></ion-input>
        <ion-text color="danger" *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)">
          <small> El teléfono es inválido </small>
        </ion-text>
      </ion-item>
    </ion-col>
    <ion-col size="12" class="ion-margin-top">
      <ion-button
        class="ion-margin-top"
        expand="block"
        fill="solid"
        shape="round"
        (click)="action()"
        [disabled]="phoneNumber.invalid"
      >
        Verificar
      </ion-button>
    </ion-col>
    <ion-col size="12">
      <ion-button
        class="ion-margin-top"
        [style.fontWeight]="'bold'"
        expand="block"
        fill="clear"
        shape="round"
        color="secondary"
        (click)="goToLogin()"
      >
        Regresar
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
