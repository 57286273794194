<ion-content no-bounce>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-text color="primary">
          @if(!accepted) {
          <h1 class="ion-text-center">Tienes una invitación como contacto en Mujer Móvil</h1>
          } @else {
          <h1 class="ion-text-center">Gracias por aceptar la invitación, ahora eres un contacto seguro</h1>
          }
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-margin-top">
      @if (!accepted) {
      <ion-col size="auto">
        <ion-button (click)="accept()" expand="block" fill="solid" shape="round" color="secondary">
          Aceptar invitación
        </ion-button>
      </ion-col>
      }
      <ion-col size="auto">
        <ion-button
          expand="block"
          color="primary"
          fill="solid"
          shape="round"
          href="https://mujermovil.com/"
          target="_blank"
        >
          Descarga Mujer Móvil
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
