// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  appId: 'app.mujermovil',
  production: false,
  version: env['npm_package_version'],
  serverUrl: '/api',
  defaultLanguage: 'es-ES',
  supportedLanguages: ['es-ES'],
  firebaseConfig: {
    apiKey: 'AIzaSyCbGyeMdRDHWAgr0A0_FJn0DgFWp3eQFGs',
    authDomain: 'mujer-movil-dev.firebaseapp.com',
    projectId: 'mujer-movil-dev',
    storageBucket: 'mujer-movil-dev.appspot.com',
    messagingSenderId: '906608990426',
    appId: '1:906608990426:web:f34d849bd672a098d3ede3',
    measurementId: 'G-BBB25939N3',
  },
  finsusWebView: 'https://app-pre.finsus.mx/welcome?codeName=mujermovil',
  visorusWebView: 'https://portal-mujermovil.pages.dev/cliente',
  jrContact: '+525542093242',
  projectName: 'mujer-movil-dev',
  googleWebClientId: '906608990426-b3fs43agpbhun49cr4cl3g795bqdv4bi.apps.googleusercontent.com',
  urlSafetyRoute: 'https://newsafetyroute-vqr4wtbk6a-uc.a.run.app',
  urlGetSafetyRoute: 'https://getsafetyroutes-vqr4wtbk6a-uc.a.run.app',
  urlSafetyActiveRoutes: 'https://getsafetyactiveroutes-vqr4wtbk6a-uc.a.run.app',
  urlCheckGeoUpdate: 'https://verifyarrivalandupdateactiveroute-vqr4wtbk6a-uc.a.run.app',
  urlCreatePanicAlert: 'https://newpanicalert-vqr4wtbk6a-uc.a.run.app',
  urlStopPanicAlert: 'https://disablepanicalert-vqr4wtbk6a-uc.a.run.app',
  urlGetCurrentPanicAlert: 'https://getcurrentpanicalert-vqr4wtbk6a-uc.a.run.app',
  urlUpdateSafetyRoute: 'https://updatesafetyroute-vqr4wtbk6a-uc.a.run.app',
  urlDelteSafetyRoute: 'https://deletesafetyroute-vqr4wtbk6a-uc.a.run.app',
  urlMujerMovil: 'https://mujer-movil-dev.web.app',
  urlCurrentSafetyActiveRoute: 'https://getcurrentsafetyactiveroute-vqr4wtbk6a-uc.a.run.app',
  urlUpdatePositionUser: 'https://updateuserposition-vqr4wtbk6a-uc.a.run.app',

  urlCreateUser: 'https://createuser-vqr4wtbk6a-uc.a.run.app',
  urlGetEncryptionKey: 'https://getencryptionkey-vqr4wtbk6a-uc.a.run.app',
  getJWT: 'https://getauthorizationtoken-azyisbfwxa-uc.a.run.app',
  getStateUFI: 'https://getservicestateufi-azyisbfwxa-uc.a.run.app',
  createPortability: 'https://portability-azyisbfwxa-uc.a.run.app',

  activation: 'https://activation-azyisbfwxa-uc.a.run.app',
  getServiceStateUFI: 'https://getservicestateufii-azyisbfwxa-uc.a.run.app',
  activeSharedPosition: 'https://activesharedposition-azyisbfwxa-uc.a.run.app',
  disableSharedPosition: 'https://disablesharedposition-azyisbfwxa-uc.a.run.app',
  getSharedPosition: 'https://getsharedposition-azyisbfwxa-uc.a.run.app',
  updateSharedPosition: 'https://updatesharedposition-azyisbfwxa-uc.a.run.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
