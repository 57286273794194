import { Component, EventEmitter, input, output } from '@angular/core';
import { CredentialsService } from '@app/auth';

@Component({
  selector: 'app-modal-share-location',
  templateUrl: './modal-share-location.component.html',
  styleUrl: './modal-share-location.component.scss',
})
export class ModalShareLocationComponent {
  timeList = [
    { value: 30, label: '30 minutos' },
    { value: 60, label: '1 hora' },
    { value: 90, label: '1 hora y 30 minutos' },
  ];

  emitTime = output<number>();
  emitStopSharing = output<void>();
  sharingLocation = input.required<boolean>();

  constructor(private credentialService: CredentialsService) {}

  setTime(time: number) {
    if (time === 0) {
      this.credentialService.clearTimeLocationEnd();
      return this.emitStopSharing.emit();
    }

    const currentTime = new Date().getTime();
    const timeEnd = currentTime + time * 60 * 1000;
    this.credentialService.setTimeLocationEnd(timeEnd);
    this.emitTime.emit(timeEnd);
  }
}
