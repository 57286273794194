import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { InvitationRoutingModule } from './invitation-routing.module';
import { InvitationComponent } from './invitation.component';
import { FirestoreService } from '@app/@shared/services/firestore-service.service';

@NgModule({
  declarations: [InvitationComponent],
  imports: [CommonModule, TranslateModule, IonicModule, InvitationRoutingModule, ReactiveFormsModule],
})
export class InvitationModule {}
