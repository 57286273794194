import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouteService } from '@app/@shared/services/route.service';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Observable, Subscription, interval, lastValueFrom } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Geolocation } from '@capacitor/geolocation';
import { CredentialsService } from '@app/auth';
import { Store } from '@ngrx/store';
import { PanicAlert } from '@app/@shared/models/panicAlert.interface';
import { selectPanicAlert } from '@app/@shared/store/panic-alert/panic-alert.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { resetPanicAlert, setPanicAlert } from '@app/@shared/store/panic-alert/Panic-alert.actions';
@UntilDestroy()
@Component({
  selector: 'app-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.scss'],
})
export class EmergencyComponent implements OnInit, OnDestroy {
  countDownTime: number = 10;
  isCountingDown: boolean = false;
  alertStarted: boolean = false;
  countdownTerminated: boolean = false;
  iconEmergency = {
    url: './assets/pin-danger.png', // url
    scaledSize: new google.maps.Size(30, 35), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(15, 33), // anchor
  };
  mapCirlceEmergency = {
    fillColor: 'rgb(234, 60, 79)',
    fillOpacity: 0.1,
    strokeColor: 'rgb(234, 60, 79)',
    strokeOpacity: 0.3,
  };
  center: google.maps.LatLngLiteral = {
    lat: 19.432608,
    lng: -99.133209,
  };
  mapOptions: google.maps.MapOptions = {
    styles: [
      {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [
          {
            color: '#000000',
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
          {
            hue: '#ff0000',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#ff0000',
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#000000',
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'off',
          },
          {
            color: '#ff0000',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#9b30f2',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#644b78',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural.landcover',
        elementType: 'all',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'landscape.natural.terrain',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#CCC2D3',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
          {
            color: '#d3d4f3',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#000000',
          },
          {
            weight: '0.01',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            weight: '0.01',
          },
        ],
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '#eeeeff',
          },
          {
            visibility: 'on',
          },
        ],
      },
    ],
    controlSize: 36,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_TOP,
    },
    streetViewControlOptions: {
      position: google.maps.ControlPosition.BLOCK_START_INLINE_END,
    },
    heading: 350,
    mapTypeId: 'roadmap',
    disableDefaultUI: true,
  };
  sharingLocation = false;
  zoom = 15;

  emergencySent = false;
  isLoadingPanicAlert = false;
  private countdownSubscription!: Subscription; // Subscription for the timer

  panicAlert$: Observable<{ panicAlert: PanicAlert }> = this.store.select(selectPanicAlert);

  constructor(
    private modalCtrl: ModalController,
    private routeService: RouteService,
    private credentialsService: CredentialsService,
    private toastController: ToastController,
    private store: Store,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.getStore();
    this.setCenterPosition();
  }

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  private getStore(): void {
    this.panicAlert$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data?.panicAlert?.id) this.emergencySent = true;
      else this.countdownTimer();
    });
  }

  async setCenterPosition(): Promise<void> {
    this.center = await this.getCurrentPosition();
  }

  async getCurrentPosition(): Promise<google.maps.LatLngLiteral> {
    const coordinates = await Geolocation.getCurrentPosition();
    const lat = coordinates.coords.latitude;
    const lng = coordinates.coords.longitude;
    return { lat, lng };
  }

  async emergencyService(): Promise<void> {
    try {
      this.isLoadingPanicAlert = true;
      const { lat, lng } = await this.getCurrentPosition();
      const resp = await lastValueFrom(
        this.routeService.createPanicAlert({
          externalId: this.getUserId(),
          type: 'panicButton',
          lastPosition: {
            lat,
            lng,
          },
        })
      );
      this.emergencySent = true;
      this.presentToast('Alerta de pánico enviada', 'top');
      this.store.dispatch(setPanicAlert({ panicAlert: resp?.data?.panicAlert }));
      this.cancelCountDown();
      this.isLoadingPanicAlert = false;
      this.close();
    } catch (error) {
      this.isLoadingPanicAlert = false;
      console.log('panicError', JSON.stringify(error));
    }
    // Your emergency service logic here
  }

  countdownTimer() {
    this.isCountingDown = true;
    this.countdownSubscription = interval(1000) // Emit every 1 second
      .pipe(
        takeWhile(() => this.countDownTime > 0) // Stop when countDownTime reaches 0
      )
      .subscribe((val) => {
        console.log('[val]', val);
        if (val >= 19 && !this.isLoadingPanicAlert) {
          this.emergencyService();
        }
        this.countDownTime--;
      });
  }
  cancelCountDown() {
    this.isCountingDown = false; // Set to false to stop displaying countdown message
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe(); // Unsubscribe to stop the timer
    }
    this.countDownTime = 20; // Reset the timer value
  }

  close() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  private getUserId(): string {
    return this.credentialsService?.credentials?.userId as string;
  }

  private async presentToast(
    message: string,
    position: 'top' | 'middle' | 'bottom' = 'top',
    color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'danger' = 'primary'
  ) {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      position,
      color,
      icon: color === 'danger' ? 'close-circle' : 'checkmark-circle',
    });

    await toast.present();
  }

  private stopPanicAlert() {
    this.routeService.stopPanicAlert().subscribe({
      next: () => {
        this.presentToast('Alerta de pánico cancelada', 'top', 'success');
        this.store.dispatch(resetPanicAlert());
        this.close();
      },
      error: (e) => {
        this.presentToast('Error al cancelar la alerta de pánico', 'top', 'danger');
      },
    });
  }

  async alertStopPanicalert() {
    const alert = await this.alertCtrl.create({
      header: 'Cancelar alerta de pánico',
      message: '¿Estás seguro de que deseas cancelar la alerta de pánico?',
      buttons: [
        {
          text: 'Regresar',
          role: 'cancel',
        },
        {
          text: 'Sí, cancelar',
          handler: () => {
            this.stopPanicAlert();
          },
        },
      ],
    });
    await alert.present();
  }
}
