import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrl: './health.component.scss',
})
export class HealthComponent {
  jrContact: string = environment.jrContact;
}
