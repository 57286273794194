import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleDate } from '../models/satefy.interface';
import { timeList } from '../constants/time';

@Pipe({
  name: 'routeDay',
  pure: false,
})
export class RouteDayPipe implements PipeTransform {
  transform(day: number, scheduleList: ScheduleDate[]): string {
    const schedule = scheduleList.find((s) => s.dayNumber === day);
    if (!schedule) return '';

    const date = new Date();
    date.setHours(schedule.estimatedArrivalTime.hour);
    date.setMinutes(schedule.estimatedArrivalTime.minutes);

    const formatMinutes = timeList().find((t) => t.value === schedule.extraWaitTimeMinutes.toString())?.name;
    return `${date.toLocaleTimeString('es-MX', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })} / ${formatMinutes}`;
  }
}
