import { Component, inject, Input, input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-permission-modal',
  standalone: false,
  templateUrl: './permission-modal.component.html',
  styleUrl: './permission-modal.component.scss',
})
export class PermissionModalComponent {
  public modalController = inject(ModalController);

  @Input() public imagePath = '';
  @Input() public message = '';

  public sendResponse(accept: boolean): void {
    this.modalController.dismiss({ accept });
  }
}
