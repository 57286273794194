<ion-content class="ion-padding-safe">
  <div class="container">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-button class="btn-close" (click)="close()" fill="clear" size="" color="light">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
          <ion-card color="light" class="emergencytext ion-padding-horizontal">
            <h1 class="ion-text-center">Protocolo de Emergencia</h1>
            @if(emergencySent) {
            <hr />
            <ion-button (click)="alertStopPanicalert()" color="primary" expand="block" fill="outline">
              <ion-icon slot="start" name="notifications-off-outline"></ion-icon>
              Detener alerta de pánico
            </ion-button>
            <hr />
            }
            <google-map height="135px" width="100%" [options]="mapOptions" [center]="center" [zoom]="zoom" #agmMap>
              <map-marker [position]="center" [icon]="iconEmergency" [title]="'Tu ubicación'"></map-marker>
              <map-circle [options]="mapCirlceEmergency" [center]="center" [radius]="150"></map-circle>
            </google-map>
            <div>
              @if (!emergencySent) {
              <span>
                <b>No estás sola.</b> Pronto enviaremos un mensaje a tus contactos registrados con tu ubicación para que
                sepan dónde estas.
              </span>
              } @else {
              <h2>Se activó el botón de pánico</h2>
              <div class="text-list" class="ion-text-start">
                <p>Te sugerimos seguir las siguientes recomendaciones:</p>
                <ul>
                  <li>Mantén la calma</li>
                  <li>Enviamos tu ubicación a tus contactos seguros</li>
                  <li>Intenta ubicar a la policía más cercana</li>
                  <li>Mantente en lugares concurridos e iluminados</li>
                </ul>
              </div>
              <h4>Contactos seguros</h4>
              <ion-item button [detail]="false">
                <!-- <ion-avatar slot="start"> -->
                <!-- <img [src]="" /> -->
                <!-- </ion-avatar> -->
                <ion-icon slot="start" name="person-circle-outline"></ion-icon>
                <ion-label>Nombre Apellido</ion-label>
                <ion-icon name="call-outline" slot="end"></ion-icon>
              </ion-item>
              <ion-item button [detail]="false">
                <!-- <ion-avatar slot="start"> -->
                <!-- <img [src]="" /> -->
                <!-- </ion-avatar> -->
                <ion-icon slot="start" name="person-circle-outline"></ion-icon>
                <ion-label>Nombre Apellido</ion-label>
                <ion-icon name="call-outline" slot="end"></ion-icon>
              </ion-item>
              <ion-item button [detail]="false">
                <!-- <ion-avatar slot="start"> -->
                <!-- <img [src]="" /> -->
                <!-- </ion-avatar> -->
                <ion-icon slot="start" name="person-circle-outline"></ion-icon>
                <ion-label>Nombre Apellido</ion-label>
                <ion-icon name="call-outline" slot="end"></ion-icon>
              </ion-item>
              <hr />
              <p>Si necesitas ayuda inmediata llama a la policía al 911.</p>
              <ion-button expand="block" color="primary" fill="solid" style="text-wrap: wrap">
                <ion-icon name="call-outline" slot="end"></ion-icon>
                <b>Llamar al 911</b>
              </ion-button>
              <hr />
              }
            </div>
            <!-- <p><b>Si necesitas ayuda inmediata</b> llama a la policía al 911.</p> -->
            @if (!emergencySent) {
            <ion-avatar color="danger" class="countdown">
              <h1>{{ countDownTime }}</h1>
            </ion-avatar>
            <ion-button
              color="danger"
              expand="block"
              fill="solid"
              [disabled]="isLoadingPanicAlert"
              (click)="emergencyService()"
            >
              <ion-text color="light">
                <h3>Enviar alerta</h3>
              </ion-text>
              <ion-icon color="light" size="large" class="ion-margin-left" name="warning-outline" slot="end"></ion-icon>
            </ion-button>
            <ion-button
              class="ion-margin-vertical"
              color="danger"
              *ngIf="isCountingDown"
              expand="block"
              fill="clear"
              [disabled]="isLoadingPanicAlert"
              (click)="cancelCountDown()"
            >
              Detener reloj
            </ion-button>
            }
            <!-- @if(!isCountingDown || emergencySent) {
            <div [style.marginTop]="'30px'">
              <ion-button
                color="danger"
                expand="block"
                fill="solid"
                class="ion-margin-vertical"
                (click)="close()"
              >
                Salir
              </ion-button>
            </div>
            } -->
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
