import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeleteUserRoutingModule } from './delete-user-routing.module';
import { IonicModule } from '@ionic/angular';
import { DeleteUserComponent } from './delete-user.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DeleteUserComponent],
  imports: [CommonModule, DeleteUserRoutingModule, IonicModule, FormsModule],
})
export class DeleteUserModule {}
