import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-nutrition',
  templateUrl: './nutrition.component.html',
  styleUrl: './nutrition.component.scss',
})
export class NutritionComponent {
  jrContact: string = environment.jrContact;
}
