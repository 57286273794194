import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteType, SafetyRoute, ScheduleDate } from '@app/@shared/models/satefy.interface';
import { RouteService } from '@app/@shared/services/route.service';
import { CredentialsService } from '@app/auth';
import { AlertController, IonRouterOutlet, LoadingController, ModalController, RangeCustomEvent } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';
import { ModalRouteScheduleComponent } from './modal-route-schedule/modal-route-schedule.component';
import { format } from 'date-fns';
import { defaultCenter, iconActive, mapCirlceActive, mapOptions } from '@app/@shared/constants/map-config';
import { timeList } from '@app/@shared/constants/time';
import { be, es } from 'date-fns/locale';

@Component({
  selector: 'app-route-add',
  templateUrl: './route-add.component.html',
  styleUrl: './route-add.component.scss',
})
export class RouteAddComponent implements OnInit, AfterViewInit {
  currentRoute!: SafetyRoute;

  daysofWeek = [
    { name: 'Lunes', value: 1 },
    { name: 'Martes', value: 2 },
    { name: 'Miércoles', value: 3 },
    { name: 'Jueves', value: 4 },
    { name: 'Viernes', value: 5 },
    { name: 'Sabado', value: 6 },
    { name: 'Domingo', value: 0 },
  ];

  days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

  timeList = timeList();

  selectedDays: number[] = [];
  selectedType = 'recurring';
  display: any;
  center: google.maps.LatLngLiteral = defaultCenter();
  mapOptions = mapOptions();
  iconActive = iconActive();
  mapCirlceActive = mapCirlceActive();
  sharingLocation = false;
  zoom = 15;
  selectedRadius = 100;

  form!: FormGroup;
  routeType: RouteType = 'single';
  dateText: string = '';

  scheduleList: ScheduleDate[] = [];
  currentDay!: number | null;

  @ViewChild('search') searchEl!: ElementRef<HTMLInputElement>;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private credentialsService: CredentialsService,
    private routeService: RouteService,
    private loadingCtrl: LoadingController,
    private router: Router,
    private ionRouterOutlet: IonRouterOutlet,
    private credentialService: CredentialsService,
    private activRoute: ActivatedRoute,
    private alerCtrl: AlertController
  ) {}

  ngOnInit() {
    this.formInit();
    this.getQueryParams();
  }

  ngAfterViewInit(): void {
    this.createSearch();
    console.log(this.searchEl);
  }

  private getQueryParams() {
    this.activRoute.queryParams.subscribe((params) => {
      const route = params['route'];
      if (route) {
        this.currentRoute = JSON.parse(route);
        this.setRoute();
      }
    });
  }

  setRoute() {
    this.form.patchValue({
      name: this.currentRoute.name,
      address: this.currentRoute.address,
      geofence: {
        center: this.currentRoute.geofence.center,
      },
    });

    if (this.currentRoute.routeType === 'single') {
      this.form.patchValue({
        waitTimeMinutes: String(this.currentRoute.arrivalSchedule['extraWaitTimeMinutes']),
        schedule: format(
          new Date(this.currentRoute.arrivalSchedule['estimatedArrivalTime']),
          "yyyy-MM-dd'T'HH:mm:ss.sss'Z'"
        ),
      });
    }

    if (this.currentRoute.routeType === 'recurring') {
      this.scheduleList = this.currentRoute.arrivalSchedule as ScheduleDate[];
      this.selectedDays = this.scheduleList.map((sl) => sl.dayNumber);
    }

    this.center = this.currentRoute.geofence['center'] as unknown as google.maps.LatLngLiteral;
    this.routeType = this.currentRoute.routeType;
    this.changeType(this.currentRoute.routeType);
    this.selectedRadius = this.currentRoute.geofence.meters;
    this.dateText = this.currentRoute.dateText;
  }

  private formInit() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      address: [null, Validators.required],
      geofence: this.fb.group({
        center: this.fb.group({
          lat: [null, Validators.required],
          lng: [null, Validators.required],
        }),
        // meters: [null, Validators.required],
      }),
      schedule: [{ value: null, disabled: true }, Validators.required],
      // dateText: [null, Validators.required],
      waitTimeMinutes: [{ value: '30', disabled: true }, Validators.required],
    });

    // this.formSchedule = this.fb.group({
    //   time: [null, Validators.required],
    //   waitTimeMinutes: ['30', Validators.required],
    // });
  }

  private createSearch() {
    const config = {
      types: ['establishment'],
      componentRestrictions: { country: 'MX' },
    };

    console.log(this.searchEl);
    const autocomplete = new google.maps.places.Autocomplete(this.searchEl.nativeElement, config);

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();
        const coords = {
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng(),
        };

        // console.log(place);
        this.form.patchValue({
          address: place.formatted_address,
          geofence: {
            center: coords,
          },
        });

        this.center = coords as any;
        // this.form.get('address')?.setValue(place.formatted_address);
        // this.form.get('geofense.center.lat')?.setValue(place.geometry?.location?.lat());
        // this.position = this.getAddressObject(this.place.address_components);
        // if (this.place.place_id) {
        //   this.gplaceResutl.emit({ place: this.place });
        //   this.searchControl.reset();
        // }
      });
    });
  }

  back(): void {
    this.router.navigate(['/safety/route']);
    // this.ionRouterOutlet.pop();
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = event.latLng.toJSON();
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }
  changeType(routeType: RouteType) {
    this.selectedType = routeType;
    const waitTime = this.form.get('waitTimeMinutes');
    const schedule = this.form.get('schedule');
    if (routeType === 'recurring') {
      waitTime?.disable();
      waitTime?.reset();

      schedule?.disable();
      schedule?.reset();
    } else {
      schedule?.enable();
      waitTime?.enable();
    }
  }
  //changeRadius($event)
  changeRadius(ev: Event) {
    this.selectedRadius = Number((ev as RangeCustomEvent).detail.value);
    this.form.markAsDirty();
  }
  checkIfDayIsSelected(day: number) {
    return this.selectedDays.includes(day);
  }
  addToSelectedDays(day: number) {
    if (this.selectedDays.includes(day)) {
      this.selectedDays = this.selectedDays.filter((d) => d !== day);
    } else {
      this.selectedDays.push(day);
    }
  }

  confirm() {
    return this.modalCtrl.dismiss('confirm');
  }

  save() {
    if (this.selectedType === 'single') {
      this.saveSingle();
    } else {
      this.saveRecurring();
    }
  }

  private validateDate(): boolean {
    // the date selected will be in the future
    const date = new Date(this.form.get('schedule')?.value);
    const now = new Date();
    return date > now;
  }

  async saveSingle(): Promise<void> {
    if (!this.validateDate()) {
      const alert = await this.alerCtrl.create({
        header: 'Alerta',
        message: 'No se puede establecer una ruta con una hora pasada',
        buttons: ['Aceptar'],
      });

      alert.present();
      return;
    }

    const loading = await this.presentLoading('Guardando ruta');
    loading.present();

    try {
      const form = this.form.value;
      const userId = this.getUserId();
      const req: SafetyRoute = {
        ...form,
        externalId: userId,
        routeType: 'single',
        dateText: this.convertToDateText(form.schedule),
        arrivalSchedule: {
          // date: new Date(form.schedule).toISOString(),
          estimatedArrivalTime: new Date(form.schedule).toISOString(),
          extraWaitTimeMinutes: Number(form.waitTimeMinutes),
        },
        geofence: {
          ...form.geofence,
          meters: this.selectedRadius,
        },
        userId,
      };

      delete req['waitTimeMinutes'];

      if (this.currentRoute) {
        await lastValueFrom(this.routeService.editSafetyRoute(this.currentRoute['id'] as string, req));
      } else {
        await lastValueFrom(this.routeService.createSafetyRoute(req));
      }

      loading.dismiss();
      this.back();
    } catch (error: any) {
      console.log(error);
      const alert = await this.alerCtrl.create({
        header: 'Alerta',
        message: error?.error?.message ?? 'Ocurrió un error al guardar la ruta',
        buttons: ['Aceptar'],
      });
      alert.present();
      loading.dismiss();
    }
  }

  async saveRecurring(): Promise<void> {
    const loading = await this.presentLoading('Guardando ruta');
    loading.present();
    try {
      const form = this.form.value;

      const dateTextList = this.scheduleList.map((sl) => {
        return this.convertToDateRecurring(sl.dayNumber, sl.estimatedArrivalTime.hour, sl.estimatedArrivalTime.minutes);
      });

      const payload = {
        ...form,
        externalId: this.getUserId(),
        geofence: {
          ...form.geofence,
          meters: this.selectedRadius,
        },
        routeType: 'recurring',
        arrivalSchedule: [...this.scheduleList],
        userId: this.getUserId(),
        dateText: dateTextList.join(', '),
      };

      if (this.currentRoute) {
        await lastValueFrom(this.routeService.editSafetyRoute(this.currentRoute['id'] as string, payload));
      } else {
        await lastValueFrom(this.routeService.createSafetyRoute(payload));
      }

      loading.dismiss();
      this.back();
    } catch (error) {
      loading.dismiss();
      this.alertError();
      console.log(error);
    }
  }

  private getUserId(): string {
    const credentials = this.credentialService.credentials;
    return credentials?.userId as string;
  }

  private convertToDateRecurring(dayNumber: number, hour: number, minutes: number) {
    const ampm = hour >= 12 ? 'PM' : 'AM';
    return `${this.days[dayNumber]} ${`${hour}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')} ${ampm}`;
  }

  private convertToDateText(dateString: string) {
    const date = new Date(dateString);
    // [format] viernes 12:00 PM
    return format(date, 'EEEE hh:mm a', { locale: es });
  }

  async presentLoading(message: string): Promise<HTMLIonLoadingElement> {
    return this.loadingCtrl.create({
      cssClass: 'custom-loading',
      message,
    });
  }

  async openModal(day: { name: string; value: number }) {
    this.currentDay = day.value;
    // Exist day
    const exist = this.scheduleList.find((s) => s.dayNumber === day.value);

    const modal = await this.modalCtrl.create({
      component: ModalRouteScheduleComponent,
      componentProps: {
        day: exist,
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();
    this.form.markAsDirty();

    if (role === 'save') {
      console.log(data, role);
      this.setSchedule(data);
    }

    if (role === 'delete') {
      this.scheduleList = this.scheduleList.filter((s) => s.dayNumber !== day.value);
    }
  }

  setSchedule(formSchedule: { time: string; waitTimeMinutes: string }): void {
    const dateTime = new Date(formSchedule.time);

    const index = this.scheduleList.findIndex((s) => s.dayNumber === this.currentDay);

    if (index !== -1) {
      this.scheduleList[index] = {
        dayNumber: this.currentDay as number,
        estimatedArrivalTime: {
          hour: dateTime.getHours(),
          minutes: dateTime.getMinutes(),
        },
        extraWaitTimeMinutes: Number(formSchedule.waitTimeMinutes),
      };
    } else {
      this.scheduleList.push({
        dayNumber: this.currentDay as number,
        estimatedArrivalTime: {
          hour: dateTime.getHours(),
          minutes: dateTime.getMinutes(),
        },
        extraWaitTimeMinutes: Number(formSchedule.waitTimeMinutes),
      });
    }

    this.currentDay = null;
  }

  get isInvalidRecurrence(): boolean {
    return this.selectedType === 'recurring' && this.scheduleList.length === 0;
  }

  clearAddress() {
    this.form.get('address')?.reset();
    this.form.get('geofence.center.lat')?.reset();
    this.form.get('geofence.center.lng')?.reset();
  }

  async alertError(): Promise<void> {
    const alert = await this.alerCtrl.create({
      header: 'Error',
      message: 'Ocurrió un error al agregar la ruta, inténtalo de nuevo.',
      buttons: ['Ok'],
    });

    alert.present();
  }
}
