import { signal } from '@angular/core';

export const defaultCenter = signal<google.maps.LatLngLiteral>({
  lat: 19.432608,
  lng: -99.133209,
});

export const mapOptions = signal<google.maps.MapOptions>({
  styles: [
    {
      featureType: 'all',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#000000',
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          hue: '#ff0000',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#ff0000',
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#ff0000',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#9b30f2',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#644b78',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'landscape.natural.terrain',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#CCC2D3',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#d3d4f3',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#000000',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'transit.station.bus',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#eeeeff',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ],
  controlSize: 36,
  zoomControl: true,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  zoomControlOptions: {
    position: google.maps.ControlPosition.LEFT_TOP,
  },
  streetViewControlOptions: {
    position: google.maps.ControlPosition.BLOCK_START_INLINE_END,
  },
  heading: 350,
  mapTypeId: 'roadmap',
  disableDefaultUI: true,
});

export const emergencyMapOptions = signal<google.maps.MapOptions>({
  styles: [
    {
      featureType: 'all',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#000000',
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          hue: '#ff0000',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#ff0000',
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#ff0000',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#9b30f2',
        },
      ],
    },
    {
      featureType: 'administrative.province',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#644b78',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'landscape.natural.terrain',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#CCC2D3',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
        {
          color: '#d3d4f3',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#000000',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          weight: '0.01',
        },
      ],
    },
    {
      featureType: 'transit.station.bus',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#eeeeff',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ],
  controlSize: 36,
  zoomControl: true,
  scrollwheel: true,
  disableDoubleClickZoom: true,
  zoomControlOptions: {
    position: google.maps.ControlPosition.LEFT_TOP,
  },
  streetViewControlOptions: {
    position: google.maps.ControlPosition.BLOCK_START_INLINE_END,
  },
  heading: 350,
  mapTypeId: 'roadmap',
  disableDefaultUI: true,
});

export const mapCirlceActive = signal<google.maps.CircleOptions>({
  fillColor: 'rgb(60, 179, 113)',
  fillOpacity: 0.1,
  strokeColor: 'rgb(60, 179, 113)',
  strokeOpacity: 0.3,
});

export const mapCirlceInactive = signal<google.maps.CircleOptions>({
  fillColor: 'rgb(175, 175, 175)',
  fillOpacity: 0.1,
  strokeColor: 'rgb(175, 175, 175)',
  strokeOpacity: 0.3,
});

export const iconInactive = signal({
  url: '/assets/pin-inactive.png', // url
  scaledSize: new google.maps.Size(30, 30), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(15, 28), // anchor
});

export const iconActive = signal({
  url: '/assets/pin.png', // url
  scaledSize: new google.maps.Size(30, 30), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(15, 28), // anchor
});
