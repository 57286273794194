import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CredentialsService } from '@app/auth';

@Injectable({
  providedIn: 'root',
})
export class PortabilidadService {
  private urlGetStateUFI = environment.getStateUFI;
  private urlCreatePortability = environment.createPortability;

  constructor(private http: HttpClient, private credentialService: CredentialsService) {}

  getStateUFI(phone: string): Observable<any> {
    return this.http.get(`${this.urlGetStateUFI}/${phone}`);
  }

  createPortability(request: any): Observable<any> {
    return this.http.post(`${this.urlCreatePortability}`, { ...request });
  }

  get getHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.credentialService!.getJwtMobility()}`,
      'x-project-name': environment.projectName,
    });
  }
}
