<div class="wrapper">
  <img src="/assets/img/BG-MM-3.png" alt="mujer movil" class="background-img" />
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
      <ion-title slot="start">
        <ion-text color="medium">
          <h1 class="ion-text-start">
            <ion-text color="light">Mujer Segura</ion-text>
          </h1>
        </ion-text>
      </ion-title>
    </ion-toolbar>
    <app-emergency-button />
  </ion-header>
  <ion-content [fullscreen]="true">
    <ion-grid fixed>
      <ion-row>
        <ion-col>
          <ion-chip button [routerLink]="['/safety']" [routerDirection]="'root'">
            <ion-icon [name]="'chevron-back-outline'" color="primary"></ion-icon>
            <ion-label color="primary">Regresar a Mujer Segura</ion-label>
          </ion-chip>
          <ion-chip button>
            <ion-label color="primary">Ayuda</ion-label>
            <ion-icon name="help-circle-outline" color="primary"></ion-icon>
          </ion-chip>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class="ion-no-margin ion-no-padding">
          <google-map height="40vh" width="100%" [options]="mapOptions" [center]="center" [zoom]="zoom" #agmMap>
            <map-marker
              [icon]="iconActive"
              *ngFor="let marker of contacts"
              [position]="marker.lastLocation!"
            ></map-marker>
          </google-map>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-item color="primary">
            <ion-icon slot="start" src="./assets/icons/people-dress.svg"></ion-icon>
            <ion-label>
              <h2>Contactos seguros</h2>
            </ion-label>

            @if(contacts.length <=2) {
            <ion-button slot="end" shape="round" (click)="openModal()" color="light" fill="solid">
              <ion-icon slot="start" name="add"></ion-icon> Nuevo
            </ion-button>
            }
          </ion-item>

          @if (!loading) {
          <ion-item
            *ngFor="let contact of contacts; index as i"
            class="ion-text-wrap"
            button
            (click)="setCenterAndZoom(contact)"
            detail="false"
          >
            <ion-avatar slot="start">
              <img [src]="'./assets/pin-primary.png'" />
            </ion-avatar>
            <ion-label class="ion-text-wrap">
              <h2>
                <b>{{ contact.name }}</b>
              </h2>
              <!-- @if(contact.lastSeen) {
                <p>
                  Actualizado: {{ contact.lastSeen | dfnsFormatDistanceToNow : dateFnsOptions }}
                </p>
              } -->

              <!-- <p>Actualizado:</p> -->
              <p>
                <small *ngIf="contact?.invitationAccepted">Invitación aceptada</small>
                <small *ngIf="!contact?.invitationAccepted">
                  <ion-text color="danger"> A espera de que acepten tu invitación </ion-text>
                </small>
              </p>
            </ion-label>
            <ion-button [id]="'btn_' + i + '_actions'" slot="end" fill="clear" color="medium" class="actions-btn">
              <ion-icon slot="icon-only" name="ellipsis-vertical-outline"></ion-icon>
            </ion-button>
            <ion-popover [trigger]="'btn_' + i + '_actions'" triggerAction="click" #popover>
              <ng-template>
                <ion-item color="primary">
                  <ion-icon slot="start" [src]="'./assets/icons/people-dress.svg'"></ion-icon>
                  <ion-label>{{ contact.name }}</ion-label>
                </ion-item>
                <ion-item color="stable" button detail="false" class="actionbtn" (click)="openModal(contact)">
                  <ion-icon slot="end" name="create-outline"></ion-icon>
                  <ion-label>Editar</ion-label>
                </ion-item>
                <ion-item
                  color="stable"
                  button
                  detail="false"
                  class="actionbtn"
                  *ngIf="!contact?.invitationAccepted"
                  (click)="shareInvitation(contact.id!)"
                >
                  <ion-icon slot="end" name="logo-whatsapp"></ion-icon>
                  <ion-label>Enviar invitación</ion-label>
                </ion-item>
                <ion-item color="stable" button detail="false" class="actionbtn" (click)="confirmDelete(contact)">
                  <ion-icon slot="end" name="trash-outline"></ion-icon>
                  <ion-label>Borrar</ion-label>
                </ion-item>
              </ng-template>
            </ion-popover>
          </ion-item>

          } @else {
          <ion-item *ngFor="let item of [0, 0, 0]">
            <ion-thumbnail slot="start">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </ion-thumbnail>
            <ion-label>
              <h3>
                <ion-skeleton-text [animated]="true" style="width: 80%"></ion-skeleton-text>
              </h3>
              <p>
                <ion-skeleton-text [animated]="true" style="width: 60%"></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text [animated]="true" style="width: 30%"></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
          }
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
