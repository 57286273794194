<ion-split-pane contentId="main-content" auto-hide="true" when="false">
  <ion-menu contentId="main-content">
    <ion-content>
      <div ion-fixed>
        <ion-img src="./assets/logo.png" class="logo"></ion-img>

        <ion-menu-toggle auto-hide="false">
          <ion-item [routerDirection]="'root'" routerLink="/home" routerLinkActive="active" detail="true" lines="none">
            <ion-icon slot="start" src="./assets/icons/house-chimney.svg" size="large"></ion-icon>
            <ion-label><span translate>Inicio</span></ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item (click)="openVisorusWebView()" detail="true" lines="none">
            <ion-icon slot="start" src="./assets/icons/mobile-notch.svg" size="large"></ion-icon>
            <ion-label><span translate>Mi teléfono</span></ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            [routerDirection]="'root'"
            routerLink="/safety"
            routerLinkActive="active"
            detail="true"
            lines="none"
          >
            <ion-icon slot="start" src="./assets/icons/street-view.svg" size="large"></ion-icon>
            <ion-label><span translate>Mujer Segura</span></ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            [routerDirection]="'root'"
            routerLink="/webview"
            routerLinkActive="active"
            detail="true"
            lines="none"
          >
            <ion-icon slot="start" src="./assets/icons/finance.svg" size="large"></ion-icon>
            <ion-label><span translate>Mis Finanzas</span></ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            [routerDirection]="'root'"
            routerLink="/health"
            routerLinkActive="active"
            detail="true"
            lines="none"
          >
            <ion-icon slot="start" src="./assets/icons/user-md.svg" size="large"></ion-icon>
            <ion-label><span translate>Mi Salud</span></ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            [routerDirection]="'root'"
            routerLink="/emotional"
            routerLinkActive="active"
            detail="true"
            lines="none"
          >
            <ion-icon slot="start" src="./assets/icons/hands-heart.svg" size="large"></ion-icon>
            <ion-label><span translate>Espacio Seguro</span></ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="false">
          <ion-item
            [routerDirection]="'root'"
            routerLink="/nutrition"
            routerLinkActive="active"
            detail="true"
            lines="none"
          >
            <ion-icon slot="start" src="./assets/icons/salad.svg" size="large"></ion-icon>
            <ion-label><span translate>Mi Nutrición</span></ion-label>
          </ion-item>
        </ion-menu-toggle>

        <div>
          <ion-menu-toggle auto-hide="false">
            <ion-item
              [routerDirection]="'root'"
              routerLink="/profile"
              routerLinkActive="active"
              detail="true"
              lines="none"
            >
              <ion-icon slot="start" src="./assets/icons/file-user.svg" size="large"></ion-icon>
              <ion-label>
                <h2>
                  {{ 'Mariana López' }}
                </h2>
                <p>Perfil de usuario</p>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <div class="ion-padding"></div>
        </div>
        <ion-item lines="none">
          <ion-chip slot="end" mode="ios" outline="true" (click)="showProfileActions()">
            <ion-label>Cerrar sesión</ion-label>
          </ion-chip>
        </ion-item>
      </div>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-split-pane>
