<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="secondary"></ion-menu-button>
    </ion-buttons>
    <ion-title slot="start">
      <ion-text color="medium"> </ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="content-infocard">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <!-- <ion-img src="assets/img/running.png"></ion-img> -->

          <ion-text color="dark">
            <p>
              En Mujer Movil entendemos que hay momentos difíciles, por eso
              <ion-text color="secondary">"Espacio Seguro"</ion-text>. es tu refugio para obtener apoyo emocional en un
              entorno cálido y confidencial.
            </p>
            <p>
              <ion-text color="secondary">Mujer Movil</ion-text> es tu aliado en cada paso hacia el bienestar emocional.
            </p>
          </ion-text>
        </ion-card>
        <ion-button expand="block" color="secondary" size="large" shape="round" [href]="'tel:' + jrContact">
          Iniciar Videoconsulta
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
