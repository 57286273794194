import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

import { LoaderComponent } from './loader/loader.component';
import { RouteDayPipe } from './pipes/route-day.pipe';
import { EmergencyComponent } from '@app/safety/emergency/emergency.component';
import { EmergencyButtonComponent } from '@app/safety/emergency-button/emergency-button.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PermissionModalComponent } from './components/permission-modal/permission-modal.component';

@NgModule({
  imports: [IonicModule, TranslateModule, CommonModule, GoogleMapsModule],
  declarations: [LoaderComponent, RouteDayPipe, EmergencyComponent, EmergencyButtonComponent, PermissionModalComponent],
  exports: [LoaderComponent, RouteDayPipe, EmergencyComponent, EmergencyButtonComponent, PermissionModalComponent],
})
export class SharedModule {}
