import { pki, util, md } from 'node-forge';
import { firstValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { WrapResponse } from '../models/common.interface';
import { CredentialsService } from '@app/auth';

@Injectable({
  providedIn: 'root',
})
export class CryptService {
  private publicEncryptKey?: string;

  constructor(private http: HttpClient, private credentialService: CredentialsService) {}

  public encryptBody(body: any) {
    if (!this.publicEncryptKey) return;

    const pk = pki.publicKeyFromPem(this.publicEncryptKey);

    const encrypt = pk.encrypt(JSON.stringify(body), 'RSA-OAEP', {
      md: md.sha256.create(),
      mgf1: {
        md: md.sha256.create(),
      },
    });

    return util.encode64(encrypt);
  }

  public async getEncryptionKey() {
    try {
      if (!this.credentialService.isAuthenticated()) return;

      const obs = this.http.post<WrapResponse<{ publicEncryptKey: string }>>(
        environment.urlGetEncryptionKey,
        {},
        { headers: this.getHeaders }
      );

      const { data } = await firstValueFrom(obs);

      this.publicEncryptKey = data.publicEncryptKey;
    } catch (error) {
      console.log(error);
    }
  }

  private get getHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.credentialService!.getJwtMobility()}`,
      'x-project-name': environment.projectName,
    });
  }
}
