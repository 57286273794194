import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { SafetyRoute, SafetyRouteResponse, UpdateGeoArrival } from '../models/satefy.interface';
import { Observable } from 'rxjs';
import { WrapResponse } from '../models/common.interface';
import { CredentialsService } from '@app/auth';
import { PanicAlert, PanicAlertRequest } from '../models/panicAlert.interface';
import { CryptService } from './crypt.service';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  urlSafety: string = environment.urlSafetyRoute;
  urlGetSafety: string = environment.urlGetSafetyRoute;
  urlSafetyActiveRoutes: string = environment.urlSafetyActiveRoutes;
  urlCheckGeoUpdate: string = environment.urlCheckGeoUpdate;
  urlCreatePanicAlert: string = environment.urlCreatePanicAlert;
  urlUpdateSafetyRoute: string = environment.urlUpdateSafetyRoute;
  urlDeleteSafetyRoute: string = environment.urlDelteSafetyRoute;

  constructor(
    private http: HttpClient,
    private credentialService: CredentialsService,
    private cryptService: CryptService
  ) {}

  getSafetyRoutes(userId: string): Observable<WrapResponse<SafetyRouteResponse>> {
    return this.http.get<WrapResponse<SafetyRouteResponse>>(`${this.urlGetSafety}/${userId}`, {
      headers: this.getHeaders,
    });
  }

  createSafetyRoute(request: SafetyRoute) {
    const payload = {
      safetyRoute: {
        ...request,
      },
    };
    return this.http.post(`${this.urlSafety}/`, payload, {
      headers: this.getHeaders,
    });
  }

  editSafetyRoute(routeId: string, request: Partial<SafetyRoute>) {
    const payload = {
      safetyRoute: {
        ...request,
      },
    };
    return this.http.put(`${this.urlUpdateSafetyRoute}/${routeId}`, payload, {
      headers: this.getHeaders,
    });
  }

  deleteSafetyRoute(routeId: string) {
    return this.http.delete(`${this.urlDeleteSafetyRoute}/${routeId}`, {
      headers: this.getHeaders,
    });
  }

  getActiveRoutes(userId: string): Observable<WrapResponse<SafetyRouteResponse>> {
    return this.http.get<WrapResponse<SafetyRouteResponse>>(`${this.urlSafetyActiveRoutes}/${userId}`, {
      headers: this.getHeaders,
    });
  }

  updateGeoArrival(id: string, req: UpdateGeoArrival) {
    const dataEncrypted = this.cryptService.encryptBody(req);

    return this.http.put(
      `${this.urlCheckGeoUpdate}/${id}`,
      { data: dataEncrypted },
      {
        headers: this.getHeaders,
      }
    );
  }

  generateRoutes() {
    return this.http.post(`https://generatedailysafetyactiveroute-azyisbfwxa-uc.a.run.app`, {});
  }

  createPanicAlert(request: PanicAlertRequest): Observable<WrapResponse<{ panicAlert: PanicAlert }>> {
    const dataEncrypted = this.cryptService.encryptBody(request);

    return this.http.post<WrapResponse<{ panicAlert: PanicAlert }>>(
      `${this.urlCreatePanicAlert}`,
      { data: dataEncrypted },
      {
        headers: this.getHeaders,
      }
    );
  }

  stopPanicAlert(): Observable<WrapResponse<{}>> {
    return this.http.put<WrapResponse<{}>>(
      `${environment.urlStopPanicAlert}/${this.getUserId()}`,
      {},
      { headers: this.getHeaders }
    );
  }

  getPanicAlert(): Observable<WrapResponse<{ panicAlert: PanicAlert }>> {
    return this.http.get<WrapResponse<{ panicAlert: PanicAlert }>>(
      `${environment.urlGetCurrentPanicAlert}/${this.getUserId()}`,
      { headers: this.getHeaders }
    );
  }

  getCurrentSafetyActiveRoute(userId: string) {
    return this.http.get(`${environment.urlCurrentSafetyActiveRoute}/${userId}`, { headers: this.getHeaders });
  }

  updatePositionUser(position: any) {
    const dataEncrypted = this.cryptService.encryptBody({ position });
    return this.http.put(
      `${environment.urlUpdatePositionUser}/${this.getUserId()}`,
      { data: dataEncrypted },
      { headers: this.getHeaders }
    );
  }

  getUserId(): string {
    return this.credentialService?.credentials?.userId as string;
  }

  getToken() {
    return this.http.get('https://gettoken-azyisbfwxa-uc.a.run.app');
  }

  getJwtMobility(tokenFirebase: string): Observable<WrapResponse<{ token: string }>> {
    return this.http.post<WrapResponse<{ token: string }>>(
      environment.getJWT,
      {},
      {
        headers: {
          Authorization: `Bearer ${tokenFirebase}`,
        },
      }
    );
  }

  get getHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.credentialService!.getJwtMobility()}`,
      'x-project-name': environment.projectName,
    });
  }
}
