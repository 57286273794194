import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { SecureRouteItem } from '@app/@shared/models/interfaces';
import { NgZone } from '@angular/core';
import { AlertController, IonPopover, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { RouteAddComponent } from '../route-add/route-add.component';
import { RouteService } from '@app/@shared/services/route.service';
import { CredentialsService } from '@app/auth';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SafetyRoute } from '@app/@shared/models/satefy.interface';
import {
  defaultCenter,
  iconActive,
  iconInactive,
  mapCirlceActive,
  mapCirlceInactive,
  mapOptions,
} from '@app/@shared/constants/map-config';
@UntilDestroy()
@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrl: './route.component.scss',
})
export class RouteComponent implements OnInit, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChildren('popover') popover!: QueryList<IonPopover>;

  iconActive = iconActive();
  iconInactive = iconInactive();
  center = defaultCenter();
  mapOptions = mapOptions();
  mapCirlceActive = mapCirlceActive();
  mapCirlceInactive = mapCirlceInactive();

  routeItems: SafetyRoute[] = [];
  display: any;

  minVisibleRadius = 100; // Minimum visible radius in meters
  zoom = 14;

  sharingLocation = false;

  constructor(
    private zone: NgZone,
    private modalCtrl: ModalController,
    private credentialService: CredentialsService,
    private routeService: RouteService,
    private router: Router,
    private alertController: AlertController,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController
  ) {}
  ngOnInit(): void {
    // this.getSafeRouteList();
  }

  ngAfterViewInit() {}

  ionViewDidEnter() {
    this.getSafeRouteList();
  }

  private getSafeRouteList() {
    const credentials = this.credentialService.credentials;
    const userId = credentials?.userId as string;

    this.routeService
      .getSafetyRoutes(userId)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.routeItems = response.data.safetyRoutes;

        console.log(this.routeItems);
        this.findZoomLevel();
      });
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = event.latLng.toJSON();
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }
  findZoomLevel() {
    if (this.routeItems.length === 0) return;
    const bounds = new google.maps.LatLngBounds();
    this.routeItems.forEach((item) => {
      bounds.extend(new google.maps.LatLng(item.geofence.center['lat'], item.geofence.center['lng']));
    });

    // Make sure the map object is already initialized
    if (this.map.googleMap) {
      this.map.googleMap.fitBounds(bounds);
      if (this.routeItems.length === 1) {
        this.map.googleMap.setZoom(14);
      }
    }
  }
  calculateRadius(zoomLevel: number | undefined, item: SecureRouteItem): number {
    if (!zoomLevel) return this.minVisibleRadius;
    if (zoomLevel > 15) return item.radius; // Returns the current radius for high zoom levels
    return this.minVisibleRadius; // Returns the minimum visible radius for lower zoom levels
  }

  shareLocation() {
    this.sharingLocation = !this.sharingLocation;
  }
  getCSSVariableValue(varName: string): string {
    const style = getComputedStyle(document.documentElement);
    return style.getPropertyValue(varName).trim();
  }
  async openModal() {
    const modal = await this.modalCtrl.create({
      component: RouteAddComponent,
      cssClass: 'modal-full-screen',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      console.log(`Hello, ${data}!`);
    }
  }

  goToAddRoute() {
    this.router.navigate(['/safety/route-add']);
  }

  centerRoute(route: SafetyRoute): void {
    this.center = {
      ...(route.geofence.center as any),
    };
  }

  async editRoute(route: SafetyRoute) {
    this.popover?.forEach((item) => {
      item.dismiss();
    });
    this.router.navigate(['/safety/route-add'], {
      queryParams: { route: JSON.stringify(route) },
    });
  }

  async confirmDelete(route: SafetyRoute) {
    this.popover?.forEach((item) => {
      item.dismiss();
    });
    const alert = await this.alertController.create({
      header: `Borrar ${route.name}`,
      subHeader: '¿Estás seguro de que deseas borrar esta ruta?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Borrar',
          role: 'confirm',
          handler: () => {
            this.deleteRoute(route['id'] as string);
          },
        },
      ],
    });

    await alert.present();
  }

  activateDeativateRoute(route: SafetyRoute) {
    this.routeService.editSafetyRoute(route.id as string, { active: !route.active }).subscribe({
      next: async () => {
        route.active = !route.active;
        const ctrl = await this.toastCtrl.create({
          message: `Ruta ${route.active ? 'activada' : 'desactivada'}`,
          duration: 3000,
          color: 'secondary',
        });
        ctrl.present();
      },
      error: async (err) => {
        const alert = await this.alertController.create({
          header: 'Alerta',
          message: err.error.message,
          buttons: ['Aceptar'],
        });
        alert.present();
      },
    });
  }

  private async deleteRoute(idRoute: string): Promise<void> {
    const loading = await this.createLoading('Borrando ruta...');
    await loading.present();

    this.routeService
      .deleteSafetyRoute(idRoute)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.getSafeRouteList();
          loading.dismiss();
        },
        error: () => {
          loading.dismiss();
        },
      });
  }

  private createLoading(message: string): Promise<HTMLIonLoadingElement> {
    return this.loadingCtrl.create({ message });
  }
}
