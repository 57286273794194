import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { forkJoin, from, lastValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
// import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { AuthenticationService, LoginContext } from './authentication.service';
import { GoogleAuthProvider, UserCredential, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { Auth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Credentials, CredentialsService } from './credentials.service';
import { FirestoreService } from '@app/@shared/services/firestore-service.service';
import { continueRregister } from '@app/@shared/models/login.interface';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Logger } from '@app/@shared/logger.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouteService } from '@app/@shared/services/route.service';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private platform: Platform,
    private loadingController: LoadingController,
    private credentialsService: CredentialsService,
    // private angularFireAuth: AngularFireAuth,
    private auth: Auth,
    private afAuth: AngularFireAuth,
    private authService: AuthenticationService,
    private fsService: FirestoreService,
    private routeService: RouteService,
    private alertController: AlertController
  ) {
    this.createForm();
  }

  ngOnInit() {}

  async login() {
    this.isLoading = true;
    // const login$ = this.authenticationService.login(this.loginForm.value);
    const form = this.loginForm.value;
    const login$ = signInWithEmailAndPassword(this.auth, form.username, form.password);
    const loadingOverlay = await this.loadingController.create({});
    const loading$ = from(loadingOverlay.present());
    forkJoin([login$, loading$])
      .pipe(
        map(([credentials, ...rest]) => credentials),
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: async (credentials) => {
          log.info(credentials);
          await this.getJWTMobility(credentials!.user!['accessToken']);
          const dataCredentials: Credentials = {
            username: credentials.user.email as string,
            token: credentials.user['accessToken'],
            userId: credentials.user.uid,
          };
          this.credentialsService.setCredentials(dataCredentials);
          await loadingOverlay.dismiss();

          this.router.navigate([this.route.snapshot.queryParams['redirect'] || '/'], { replaceUrl: true });
        },
        error: async (error) => {
          const errorCode = error as { code: string };
          this.loginForm.get('username')?.setValue('');
          this.loginForm.get('password')?.setValue('');

          await loadingOverlay.dismiss();
          switch (errorCode?.code) {
            case 'auth/user-not-found':
              this.error = 'El usuario ingresado no existe o el email es incorrecto';
              break;
            case 'auth/invalid-email':
              this.error = 'El email no tiene un formato válido';
              break;
            case 'auth/wrong-password':
              this.error = 'Contraseña incorrecta';
              break;
            case 'auth/internal-error':
              this.error = 'Se obtuvo un error inesperado al procesar la solicitud en el servidor';
              break;
            default:
              this.error = 'El usuario ingresado no existe o el email es incorrecto';
              break;
          }
        },
      });
  }

  get isWeb(): boolean {
    return !this.platform.is('cordova');
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }

  loading!: HTMLIonLoadingElement;

  async loginWithGoogle(): Promise<void> {
    try {
      let user: any;
      let uid: string;
      let resp: any;
      let token: string;

      if (this.platform.is('capacitor')) {
        // await this.signutGoogle();
        let googleUser = await GoogleAuth.signIn();
        console.log(googleUser);
        const googleCredential = GoogleAuthProvider.credential(googleUser.authentication.idToken);

        this.loading = await this.loadingController.create({});
        await this.loading.present();

        resp = await this.afAuth.signInWithCredential(googleCredential);
        user = resp.user;
        uid = user.uid;
        token = await user.getIdToken();

        // console.log();
      } else {
        resp = await this.authService.loginWithGoogle();
        uid = resp.user.uid;
        user = resp.user;
        token = resp['user']['accessToken'];
      }

      const isExist = await this.existUser(uid);

      if (isExist) {
        // go to home
        await this.getJWTMobility(token);
        this.credentialsService.setCredentials({
          username: user.email as string,
          token,
          userId: user.uid,
        });

        await this.loading?.dismiss();
        this.router.navigate(['/'], { replaceUrl: true });
        return;
      }

      await this.loading?.dismiss();
      // go to register
      this.continueRegister({
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        currentProvider: 'google',
        token,
      });
    } catch (error) {
      this.loading?.dismiss();
      console.error('errorr', JSON.stringify(error));
    }
  }

  async existUser(uid: string): Promise<boolean> {
    const resp = await this.fsService.getDocQuery('user', 'uid', '==', uid);
    return !!resp;
  }

  continueRegister(params: continueRregister): void {
    this.router.navigate(['/register'], {
      replaceUrl: true,
      queryParams: params,
    });
  }

  async signutGoogle() {
    if (this.platform.is('capacitor')) {
      await GoogleAuth.signOut();
    }
  }

  async getJWTMobility(tokenFirebase: string) {
    try {
      const resp = await lastValueFrom(this.routeService.getJwtMobility(tokenFirebase));
      this.credentialsService.setJwtMobility(resp.data.token);
    } catch (error) {
      console.error('errorr', JSON.stringify(error));
    }
  }

  async resetPassword() {
    const alert = await this.alertController.create({
      header: 'Restablecer contraseña',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Tu correo electrónico',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Restablecer',
          handler: async (data: any) => {
            await this.afAuth.sendPasswordResetEmail(data.email);

            const successAlert = await this.alertController.create({
              header: 'Correo Enviado',
              message: 'Se ha enviado un correo para restablecer tu contraseña.',
              buttons: ['OK'],
            });
            await successAlert.present();
          },
        },
      ],
    });

    await alert.present();
  }
}
