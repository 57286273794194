import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmotionalRoutingModule } from './emotional-routing.module';
import { EmotionalComponent } from './emotional.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EmotionalComponent],
  imports: [CommonModule, TranslateModule, IonicModule, EmotionalRoutingModule],
})
export class EmotionalModule {}
