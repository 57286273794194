<div class="wrapper">
  <img src="/assets/img/BG-MM-3.png" alt="mujer movil" class="background-img" />
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
      <ion-title slot="start">
        <ion-text color="medium">
          <h1 class="ion-text-start">
            <ion-text color="light">Mujer Segura</ion-text>
          </h1>
        </ion-text>
      </ion-title>
    </ion-toolbar>
    <app-emergency-button />
  </ion-header>
  <ion-content [fullscreen]="true">
    <ion-grid fixed class="ion-no-padding">
      <ion-row class="map-container">
        <ion-col size="12" class="ion-no-margin ion-no-padding">
          <google-map
            #mapSafety
            mapId="mapSafety"
            height="100%"
            width="100%"
            [options]="mapOptions"
            [center]="center"
            [zoom]="zoom"
            (mapClick)="moveMap($event)"
            (mapMousemove)="move($event)"
            (mapInitialized)="mapLoaded($event)"
          >
            @for (position of markerPositions; track position) {
            <map-marker [position]="position" [options]="markerOptions" />
            } @for (circleData of mapCircleList; track circleData) {
            <map-circle
              [center]="{
                lat: circleData.circle.getCenter()!.lat(),
                lng: circleData.circle.getCenter()!.lng()
              }"
              [radius]="circleData.circle.getRadius()"
              [options]="circleData.options"
              (circleClick)="openInfoWindow(circleData)"
            />
            }
            <!-- <map-marker [position]="currentLocation" [options]="markerOptions" /> -->
            <!-- <map-advanced-marker
            [position]="currentLocation"
          ></map-advanced-marker> -->
            <map-marker [position]="currentLocation" [icon]="iconActive" />
            <map-circle [center]="currentLocation" [radius]="150" [options]="mapCirlceActive" />
          </google-map>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-card class="button-bar">
      <ion-grid fixed>
        <ion-row>
          <ion-col size="12">
            <ion-item lines="none" class="ion-text-center">
              <!-- <ion-chip slot="start" [color]="sharingLocation ? 'light' : 'light'" id="share-location-button">
                <ion-icon
                  [name]="sharingLocation ? 'navigate' : 'close'"
                  [color]="sharingLocation ? 'success' : 'danger'"
                ></ion-icon>
                <ion-label>{{ sharingLocation ? 'Compartes tu ubicación' : 'Activa tu ubicación' }}</ion-label>
              </ion-chip> -->

              <!-- Share location -->
              <ion-popover trigger="share-location-button" [dismissOnSelect]="true">
                <ng-template>
                  <app-modal-share-location
                    (emitTime)="checkLocation($event)"
                    (emitStopSharing)="clearIntervalSharing()"
                    [sharingLocation]="sharingLocation"
                  ></app-modal-share-location>
                </ng-template>
              </ion-popover>

              <ion-button slot="end" class="ion-float-end" color="light" fill="clear">
                <ion-icon slot="icon-only" [name]="'help-circle-outline'"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card button expand="block" [routerLink]="['/safety', 'route']" [routerDirection]="'root'">
              <ion-icon src="./assets/icons/route.svg"></ion-icon>
              <br />
              Mis Rutas Seguras
            </ion-card>
          </ion-col>
          <ion-col>
            <ion-card button expand="block" [routerLink]="['/safety', 'contacts']" [routerDirection]="'root'">
              <ion-icon src="./assets/icons/people.svg"></ion-icon>
              <br />
              Mis Contactos
            </ion-card>
          </ion-col>
          <ion-col> </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
    <img src="/assets/img/mujermovil123.png" class="floating-img" />
  </ion-content>
</div>
