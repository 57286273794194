import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { SafetyComponent } from './safety.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '', component: SafetyComponent, data: { title: marker('Safety') } },
  {
    path: 'safety/contacts-add',
    loadChildren: () => import('./contacts-add/contacts-add.module').then((m) => m.ContactsAddModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class SafetyRoutingModule {}
