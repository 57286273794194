import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrentProvider } from '@app/@shared/models/login.interface';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { Auth, createUserWithEmailAndPassword, GoogleAuthProvider, UserCredential } from '@angular/fire/auth';
import { AuthenticationService } from '@app/auth/authentication.service';
import { FirestoreService } from '@app/@shared/services/firestore-service.service';
import { CredentialsService } from '@app/auth/credentials.service';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectTokenNotification } from '@app/@shared/store/user/user.reducer';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { RouteService } from '@app/@shared/services/route.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-register',
  templateUrl: './form-register.component.html',
  styleUrl: './form-register.component.scss',
})
export class FormRegisterComponent implements OnInit {
  form!: FormGroup;
  loading!: HTMLIonLoadingElement;
  currentProvider!: CurrentProvider;
  uidUser!: string;
  token!: string;

  tokenNotificationUser$ = this.store.select(selectTokenNotification);
  tokenNotification!: string;

  constructor(
    private fb: FormBuilder,
    private loadingCtrl: LoadingController,
    private auth: Auth,
    private alertCtrl: AlertController,
    private router: Router,
    private authService: AuthenticationService,
    private fsService: FirestoreService,
    private credentialsService: CredentialsService,
    private activRoute: ActivatedRoute,
    private store: Store,
    private platform: Platform,
    private afAuth: AngularFireAuth,
    private routeService: RouteService
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.getQueryParams();
    this.getNotificationToken();
  }

  private getNotificationToken(): void {
    this.tokenNotificationUser$.subscribe((token) => (this.tokenNotification = token));
  }

  private getQueryParams(): void {
    this.activRoute.queryParams.subscribe((params) => {
      this.currentProvider = params['currentProvider'];
      this.token = params?.['token'] || null;
      this.uidUser = params['uid'];
      const email = params['email'];
      const name = params['name'];
      if (this.currentProvider) {
        this.form.patchValue({
          name,
        });
        this.disableSetField('email', email);
        this.disableSetField('password');
        this.disableSetField('repeatedPassword');
      }
    });
  }

  private async formInit() {
    this.form = this.fb.group(
      {
        name: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        repeatedPassword: ['', Validators.required],
        birthday: ['', Validators.required],
      },
      {
        validator: this.repeatPassword('password', 'repeatedPassword'),
      }
    );
  }

  private repeatPassword(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['repeatPasswordValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ repeatPasswordValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  async submitAction() {
    if (!this.currentProvider) {
      await this.register();
    } else {
      await this.saveData();
    }
  }

  async saveData(): Promise<void> {
    const loading = await this.setLoading('Registrando usuario...');
    await loading.present();
    try {
      const data = this.form.value;
      delete data?.repeatedPassword;
      delete data?.password;
      const completeData = this.form.getRawValue();
      data.uid = this.uidUser;
      data.email = completeData.email;
      data.tokenNotification = this.tokenNotification;
      data.phone = sessionStorage.getItem('registerPhone');

      console.log('[UID user]', this.uidUser);
      console.log('[Data]', data);
      await this.fsService.createDocument('user', this.uidUser, {
        ...data,
        timestamp: this.fsService.getDateTimestamp(new Date()),
      });

      const respTokenMobility = await lastValueFrom(this.routeService.getJwtMobility(this.token));

      const resp = await lastValueFrom(
        this.authService.createNewUser(
          {
            email: completeData.email,
            externalId: this.uidUser,
            projectName: 'mujer-movil',
          },
          respTokenMobility.data.token
        )
      );

      this.credentialsService.setJwtMobility(respTokenMobility.data.token);

      console.log(resp);

      this.goToLogin(completeData.email, this.token);
      loading.dismiss();
    } catch (error: any) {
      console.log(error.status);
      console.log(error.error);
      if (error?.status === 400) {
        this.presentAlert('Error', error.error.message);
      }
      if (error?.status === 401) {
        this.presentAlert('Error', 'No autorizado');
      }
      console.error(error);
      loading.dismiss();
    }
  }

  async register(): Promise<void> {
    this.loading = await this.createLoading('Registrado usuario...');
    await this.loading.present();
    try {
      const email = this.form.get('email')?.value;
      const password = this.form.get('password')?.value;
      const userCredential = await createUserWithEmailAndPassword(this.auth, email, password);
      // Signed in
      const user = userCredential.user;
      this.token = await user.getIdToken();

      this.uidUser = user.uid;
      this.saveData();
      this.loading.dismiss();
    } catch (error: any) {
      console.log(error);
      const errorCode = error.code;

      if (errorCode === 'auth/email-already-in-use') {
        this.presentAlert('Error', 'El correo ya está en uso');
      } else {
        this.presentAlert('Error', 'Error inesperado, intente más tarde');
      }
      this.loading.dismiss();
    }
  }

  async presentAlert(header: string, message: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['Aceptar'],
    });
    await alert.present();
  }

  private async setLoading(message: string): Promise<HTMLIonLoadingElement> {
    return await this.loadingCtrl.create({ message });
  }

  async loginWithGoogle(): Promise<void> {
    try {
      let user: any;
      let uid: string;
      let resp: any;
      let token: string;

      if (this.platform.is('capacitor')) {
        await this.signutGoogle();
        let googleUser = await GoogleAuth.signIn();
        const googleCredential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
        resp = (await this.afAuth.signInWithCredential(googleCredential)) as any;
        user = resp.user;
        uid = user.uid;
        token = await user.getIdToken();
      } else {
        resp = await this.authService.loginWithGoogle();
        uid = resp.user.uid;
        user = resp.user;
        token = resp['token'];
      }

      this.uidUser = uid;
      const isExist = await this.existUser(uid);
      this.currentProvider = 'google';

      if (isExist) {
        return this.goToLogin(user.email, this.token);
      }

      console.log('[USER]', user);

      // Continue register
      this.form.get('name')?.setValue(user.displayName);
      this.disableSetField('email', user.email);
      this.disableSetField('password');
      this.disableSetField('repeatedPassword');
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  }

  private disableSetField(field: string, value?: string): void {
    if (value) this.form.get(field)?.setValue(value);
    this.form.get(field)?.disable();
  }

  private createLoading(message: string): Promise<HTMLIonLoadingElement> {
    return this.loadingCtrl.create({ message });
  }

  async existUser(uid: string): Promise<boolean> {
    const resp = await this.fsService.getDocQuery('user', 'uid', '==', uid);
    return !!resp;
  }

  private goToLogin(email: string, token: string): void {
    this.credentialsService.setCredentials({
      username: email,
      token,
      userId: this.uidUser,
    });
    this.router.navigate(['/'], { replaceUrl: true });
  }

  private async signutGoogle() {
    if (this.platform.is('capacitor')) {
      await GoogleAuth.signOut();
    }
  }

  get passwordRepeated(): boolean {
    const ctrl = this.form.get('repeatedPassword')?.errors?.['repeatPasswordValidator'];
    return (ctrl && !ctrl?.pristine) || false;
  }
}
