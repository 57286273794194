<ion-content no-bounce>
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-justify-content-center ion-no-padding">
      <ion-col size-sm="8" size-lg="6" size-xl="4" class="ion-align-self-center ion-no-padding">
        <div class="ion-text-center logo-container">
          <ion-img src="./assets/logo.png" class="logo"></ion-img>

          <h1>
            {{ [1].includes(currentStep) ? 'Portabilidad' : 'Regístrate' }}
          </h1>
        </div>

        <ion-row class="ion-justify-content-center">
          <ion-col size="10" class="title">
            @if (currentStep !== 2) {
            <ion-text color="primary">
              <p class="ion-no-margin text-step">{{ titleStep }}</p>
            </ion-text>
            }
          </ion-col>

          <ion-col size="10" class="ion-margin-top">
            <div [@stepAnimation]="currentStep">
              @switch (currentStep) { @case (0) {
              <app-phone-number (outputStep)="currentStep = 1" (outputRegister)="currentStep = 2" />
              } @case (1) {
              <app-portabilidad
                (emitBack)="backStep()"
                [existPhoneNumberCurrent]="existPhoneNumberCurrent"
                (emitNextStep)="currentStep = 2"
              />
              } @case (2) {
              <app-form-register />
              } }
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
