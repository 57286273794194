import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EmergencyComponent } from '../emergency/emergency.component';
import { Store } from '@ngrx/store';
import { selectPanicAlert } from '@app/@shared/store/panic-alert/panic-alert.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-emergency-button',
  templateUrl: './emergency-button.component.html',
  styleUrl: './emergency-button.component.scss',
})
export class EmergencyButtonComponent {
  panicAlertActive = false;
  panicAlert$ = this.store.select(selectPanicAlert);

  constructor(private modalCtrl: ModalController, private store: Store) {
    this.getStore();
  }

  private getStore(): void {
    this.panicAlert$.pipe(untilDestroyed(this)).subscribe((panicAlert) => {
      this.panicAlertActive = !!panicAlert.panicAlert.id;
    });
  }

  async openEmergency() {
    const modal = await this.modalCtrl.create({
      component: EmergencyComponent,
      cssClass: 'modal-full-screen',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      // console.log(`Hello, ${data}!`);
    }
  }
}
