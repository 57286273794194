<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title slot="start">
      <ion-text color="medium">
        <h1 class="ion-text-start">
          <ion-text color="light">Mujer Segura</ion-text>
        </h1>
      </ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="content-infocard">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <ion-text color="primary" class="ion-text-center">
            <ion-spinner name="lines-sharp" *ngIf="isLoading"></ion-spinner>
            <h2>Gestiona tus Finanzas</h2>
            <h4>Conectando de manera segura para tu independencia financiera</h4>
          </ion-text>
          <ion-img src="assets/img/coin.png"></ion-img>
          <div class="finsus-container">
            Powered By

            <ion-img src="assets/logo-finsus.png" class="finsus-logo"></ion-img>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
