import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouteAddRoutingModule } from './route-add-routing.module';
import { RouteAddComponent } from './route-add.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/@shared';
import { ModalRouteScheduleComponent } from './modal-route-schedule/modal-route-schedule.component';

@NgModule({
  declarations: [RouteAddComponent, ModalRouteScheduleComponent],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    SharedModule,
    GoogleMapsModule,
    FormsModule,
    RouteAddRoutingModule,
    ReactiveFormsModule,
  ],
})
export class RouteAddModule {}
