import { Component, OnDestroy, output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirestoreService } from '@app/@shared/services/firestore-service.service';
import { PortabilidadService } from '@app/@shared/services/portabilidad.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { finalize, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrl: './phone-number.component.scss',
})
export class PhoneNumberComponent implements OnDestroy {
  phoneNumber = this.fb.control(null, [
    Validators.required,
    Validators.maxLength(10),
    Validators.minLength(10),
    Validators.pattern('^[0-9]+$'),
  ]);

  outputStep = output<void>();
  outputRegister = output<void>();

  $subs = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private alertController: AlertController,
    private portabilidadService: PortabilidadService,
    private router: Router,
    private loadingController: LoadingController,
    private firestoreService: FirestoreService
  ) {}

  ngOnDestroy(): void {
    this.$subs.next();
    this.$subs.complete();
  }

  async action() {
    this.getStateUFI();
  }

  async createAlertPortability() {
    const alert = await this.alertController.create({
      header: 'La compañía telefónica no pertenece Mujer Móvil',
      subHeader: '¿Deseas realizar el proceso de portabilidad?',
      message: '* Necesitas tener un chip con teléfono de Mujer Móvil para realizar la portabilidad',
      buttons: [
        {
          text: 'No, salir',
          handler: () => {
            this.goToLogin();
          },
        },
        {
          text: 'Si, continuar',
          handler: () => {
            this.outputStep.emit();
          },
        },
      ],
    });

    alert.present();
  }

  async createAlertAccountExist(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'El número ingresado ya está registrado',
      message: 'Por favor, inicia sesión',
      buttons: [
        {
          text: 'Iniciar sesión',
          handler: () => {
            this.goToLogin();
          },
        },
      ],
    });

    await alert.present();

    alert.onDidDismiss().then(() => {
      this.goToLogin();
    });
  }

  private async getStateUFI(): Promise<void> {
    await this.showLoading();
    const exist = await this.checkNumberExist();
    if (exist) {
      await this.hideLoading();
      this.createAlertAccountExist();
      return;
    }
    this.portabilidadService
      .getStateUFI(this.phoneNumber.value!)
      .pipe(
        takeUntil(this.$subs),
        finalize(() => this.hideLoading())
      )
      .subscribe({
        next: (res) => {
          if (res.data?.['information']) {
            sessionStorage.setItem('registerPhone', this.phoneNumber.value!);
            this.outputRegister.emit();
          } else {
            this.createAlertPortability();
          }
        },
        error: async (error) => {
          this.createAlertPortability();
        },
      });
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  private async showLoading() {
    const loading = await this.loadingController.create({
      message: 'Verificando...',
    });
    await loading.present();
  }

  private async hideLoading() {
    await this.loadingController.dismiss();
  }

  private async checkNumberExist() {
    try {
      const res = await this.firestoreService.getDocQuery('user', 'phone', '==', this.phoneNumber.value!);
      return res.length > 0;
    } catch (error) {
      return false;
    }
  }
}
