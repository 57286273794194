import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from '@app/@shared/models/contacts.interface';
import { ContactsService } from '@app/@shared/services/contacts.service';
import { FirestoreService } from '@app/@shared/services/firestore-service.service';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { environment } from '@env/environment';
import { CredentialsService } from '@app/auth';

@Component({
  selector: 'app-contacts-add',
  templateUrl: './contacts-add.component.html',
  styleUrl: './contacts-add.component.scss',
})
export class ContactsAddComponent implements OnInit {
  @Input() contact!: Contact;

  form!: FormGroup;

  idContact!: string;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private contactService: ContactsService,
    private fsService: FirestoreService,
    private alertCtrl: AlertController,
    private platform: Platform,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.formInit();
    this.patchData();
  }

  private patchData(): void {
    if (!this.contact) return;
    this.form.patchValue({
      ...this.contact,
    });
  }

  private formInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      phoneNumber: [
        '',
        [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')],
      ],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  async saveContact(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    try {
      // Create
      if (!this.contact) {
        form.createdAt = this.fsService.getDateTimestamp(new Date());
        form.invitationAccepted = false;
        form.userId = this.getUserId();
        const { id } = await this.contactService.createContact(form);
        this.idContact = id;

        if (this.platform.is('capacitor')) {
          await this.alertShareInvitation();
        } else {
          this.confirm();
        }
      } else {
        // Edit
        await this.contactService.updateContact(form, this.contact.id as string);
        this.confirm();
      }
    } catch (error) {}
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(null, 'confirm');
  }

  async shareInvitation(): Promise<void> {
    await Share.share({
      title: '',
      text: 'Hola! Te invito a unirte a mi red de contactos seguridad \nIngresa a la siguiente liga para aceptar\n',
      url: `${environment.urlMujerMovil}/invitation/${this.contact?.id || this.idContact}`,
      dialogTitle: '',
    });
    this.confirm();
  }

  alertShareInvitation(): void {
    this.alertCtrl
      .create({
        header: 'Compartir invitación',
        message: 'Comparte la invitación con tu contacto',
        buttons: [
          {
            text: 'Después',
            handler: () => {
              this.confirm();
            },
          },
          {
            text: 'Compartir',
            handler: () => {
              this.shareInvitation();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  private getUserId(): string {
    return this.credentialsService?.credentials?.userId as string;
  }
}
