import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { User } from '../models/user.model';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class VisorusService {
  constructor(private iab: InAppBrowser) {}

  public async generateUrlWebview(user: User) {
    let { phone, email, name, lastname } = user;

    lastname = lastname.replace(' ', '-');

    const url = `${environment.visorusWebView}/${phone}/${email}/${name}-${lastname}`;

    const options: InAppBrowserOptions = {
      toolbar: 'no',
      location: 'no',
      zoom: 'no',
      hideurlbar: 'yes',
      fullscreen: 'yes',
      footer: 'no',
      hardwareback: 'no',
      mediaPlaybackRequiresUserAction: 'no',
      shouldPauseOnSuspend: 'no',
      useWideViewPort: 'yes',
      hidden: 'yes',
    };

    const browser = this.iab.create(url, '_black', options);

    const subsLoad = browser.on('loadstop').subscribe(() => {
      browser.show();
      subsLoad.unsubscribe();
    });
  }
}
