<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title slot="start">
      <ion-text color="medium">
        <h1 class="ion-text-start">
          <ion-text color="light">Mujer Segura</ion-text>
        </h1>
      </ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="content-infocard">
  <!--card con datos editables de usuario y boton de guardar-->
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-card class="ion-no-margin ion-no-padding">
          <ion-text color="primary" class="ion-text-center"> <h2>Tus datos personales</h2></ion-text>
          <h4 class="ion-text-center">En Mujer Móvil, tu eres muy valiosa; déjanos conocerte mejor</h4>

          <ion-img src="assets/img/chilling.png"></ion-img>

          <ion-item lines="full">
            <ion-label position="fixed">
              <ion-text color="primary"> Nombre(s) </ion-text>
            </ion-label>
            <ion-input type="text" value="Mariana"></ion-input>
          </ion-item>
          <ion-item lines="full">
            <ion-label position="fixed">
              <ion-text color="primary"> Apellido(s) </ion-text>
            </ion-label>
            <ion-input type="text" value="López"></ion-input>
          </ion-item>
          <ion-item lines="full">
            <ion-label position="fixed">
              <ion-text color="primary"> Correo </ion-text>
            </ion-label>
            <ion-input type="email" value="mariana.lopez@gmail.com"></ion-input>
          </ion-item>
          <ion-item lines="full">
            <ion-label position="fixed">
              <ion-text color="primary"> Teléfono </ion-text>
            </ion-label>
            <ion-input type="tel" value="5536524152"></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label position="fixed">
              <ion-text color="primary"> Cumpleaños </ion-text>
            </ion-label>
            <ion-input type="date" value=""></ion-input>
          </ion-item>
          <div class="ion-padding-top"></div>
          <ion-button expand="block" shape="round" color="primary">Guardar</ion-button>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
