import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { StepRegister } from '@app/@shared/models/register.interface';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  animations: [
    trigger('stepAnimation', [
      transition(':increment', [
        style({ position: 'relative' }),
        query(
          ':enter, :leave',
          [
            style({
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }),
          ],
          { optional: true }
        ),
        query(':enter', [style({ transform: 'translateX(100%)' })], {
          optional: true,
        }),
        group([
          query(':leave', [animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))], { optional: true }),
          query(':enter', [animate('300ms ease-out', style({ transform: 'translateX(0)' }))], { optional: true }),
        ]),
      ]),
      transition(':decrement', [
        style({ position: 'relative' }),
        query(
          ':enter, :leave',
          [
            style({
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }),
          ],
          { optional: true }
        ),
        query(':enter', [style({ transform: 'translateX(-100%)' })], {
          optional: true,
        }),
        group([
          query(':leave', [animate('300ms ease-out', style({ transform: 'translateX(100%)' }))], { optional: true }),
          query(':enter', [animate('300ms ease-out', style({ transform: 'translateX(0)' }))], { optional: true }),
        ]),
      ]),
    ]),
  ],
})
export class RegisterComponent {
  form!: FormGroup;

  constructor(private fb: FormBuilder, private router: Router) {}

  async back() {
    this.router.navigate(['/login']);
  }

  currentStep: StepRegister = StepRegister.PHONE_NUMBER;
  existPhoneNumberCurrent = false;

  phoneNumber = this.fb.control(null, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]);

  get titleStep() {
    switch (this.currentStep) {
      case StepRegister.PHONE_NUMBER:
        return 'Ingresa tu teléfono Mujer móvil';
      case StepRegister.PORTABILIDAD:
        return 'Envía un mensaje de texto (SMS) con la palabra NIP al 051 desde el número que deseas conservar.';
      case StepRegister.ACTIVATION:
        return 'Regístrate';
    }
  }

  backStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }
}
