import { Injectable } from '@angular/core';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  DocumentReference,
  deleteDoc,
  updateDoc,
  DocumentData,
  getDocs,
  collection,
  addDoc,
  Timestamp,
  where,
  WhereFilterOp,
  query,
  Query,
  onSnapshot,
} from 'firebase/firestore';

// import { docData } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { Logger } from '../logger.service';

const log = new Logger('init');
@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  db = getFirestore();

  constructor() {}

  async getDocument(colName: string, id: string) {
    const docRef = doc(this.db, colName, id);

    const document = await getDoc(docRef);

    if (document.exists()) {
      return document.data();
    } else {
      return undefined;
    }
  }

  async getCollection(colName: string) {
    const querySnapshot = await getDocs(collection(this.db, colName));
    const dataResult: DocumentData[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();

      data['id'] = doc.id;

      dataResult.push(data);
    });
    return dataResult;
  }

  async saveDocument(colName: string, data: any) {
    const colRef = collection(this.db, colName);
    const newDoc = await addDoc(colRef, data);

    return newDoc;
  }

  async timestampDate() {
    const date = Timestamp.now();

    return date;
  }

  getDateTimestamp(date: Date): Timestamp {
    const timestamp = Timestamp.fromDate(date);
    return timestamp;
  }

  async createDocument(colName: string, id: string, data: any): Promise<{ success: boolean; message: string }> {
    const docRef = doc(this.db, colName, id);

    try {
      await setDoc(docRef, data);
      return { success: true, message: 'Document successfully written!' };
    } catch (error: any) {
      return {
        success: false,
        message: `Error writing document: ${error.message}`,
      };
    }
  }

  async deleteDocument(colName: string, id: string): Promise<{ success: boolean; message: string }> {
    const docRef = doc(this.db, colName, id);

    try {
      await deleteDoc(docRef);
      return { success: true, message: 'Document successfully deleted!' };
    } catch (error: any) {
      return {
        success: false,
        message: `Error deleting document: ${error.message}`,
      };
    }
  }

  async updateDocument(colName: string, id: string, data: any): Promise<{ success: boolean; message: string }> {
    const docRef = doc(this.db, colName, id);

    try {
      await updateDoc(docRef, data);
      return { success: true, message: 'Document successfully updated!' };
    } catch (error: any) {
      return {
        success: false,
        message: `Error updating document: ${error.message}`,
      };
    }
  }

  setReference(collection: string, id: string): DocumentReference {
    return doc(this.db, collection, id);
  }

  async getReferenceDocument(reference: DocumentReference): Promise<DocumentData | undefined> {
    const docSnap = await getDoc(reference);

    return docSnap.data();
  }

  async getReferenceId(reference: DocumentReference): Promise<string | undefined> {
    const docSnap = await getDoc(reference);

    return docSnap.id;
  }

  async getDocQuery(
    colName: string,
    queryColum: any,
    queryOperator: WhereFilterOp,
    queyValue: string | DocumentReference<unknown> | boolean | number | any[]
  ) {
    try {
      const resultQuery: any = [];
      const q = query(collection(this.db, colName), where(queryColum, queryOperator, queyValue));

      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((doc) => {
          resultQuery.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        return resultQuery;
      } else {
        return undefined;
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  async getMultipleConditionsQuery(
    colName: string,
    conditions: {
      column: string;
      operator: WhereFilterOp;
      value: string | DocumentReference<unknown> | boolean | number | any[];
    }[]
  ) {
    try {
      const resultQuery: any = [];
      const queryRef = collection(this.db, colName);
      let q: Query<DocumentData> = queryRef;

      conditions.forEach((condition) => {
        q = query(q, where(condition.column, condition.operator, condition.value));
      });

      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((doc) => {
          resultQuery.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        return resultQuery;
      } else {
        return undefined;
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  listenToMultipleConditionsQuery(
    colName: string,
    conditions: {
      column: string;
      operator: WhereFilterOp;
      value: string | DocumentReference<unknown> | boolean | number | any[];
    }[]
  ) {
    const resultQuery: any[] = [];
    const data$ = new Subject<any[]>();

    const queryRef = collection(this.db, colName);
    let q: Query<DocumentData> = queryRef;

    conditions.forEach((condition) => {
      q = query(q, where(condition.column, condition.operator, condition.value));
    });

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      resultQuery.length = 0; // Clear the array

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          resultQuery.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        data$.next(resultQuery); // Emit the new data
      } else {
        data$.next([]); // Emit an empty array
      }
    });

    return {
      data$: data$.asObservable(), // Return the data as an Observable
      unsubscribe, // Also return the unsubscribe function
    };
  }
}
