import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { setTokenNotification } from './user.actions';

export interface UserStore {
  tokenNotification: string;
}

export const initialState: UserStore = {
  tokenNotification: '',
};

export const userReducer = createReducer(
  initialState,
  on(
    setTokenNotification,
    (state, { token }): UserStore => ({
      ...state,
      tokenNotification: token,
    })
  )
);

// selectors
export const selectUserState = createFeatureSelector<UserStore>('user');

export const selectTokenNotification = createSelector(selectUserState, (state: UserStore) => state.tokenNotification);
