<div class="wrapper">
  <img src="/assets/img/BG-MM-3.png" alt="mujer movil" class="background-img" />
  <ion-content>
    <div class="modal-content">
      <ion-card>
        <div class="container-message">
          <img [src]="imagePath" alt="Permiso" />
          <ion-text color="dark">
            <h3>Permisos requeridos</h3>
          </ion-text>
          <hr />
          <p style="text-align: center">
            Mujer Móvil necesita acceso a tu ubicación precisa, ubicación aproximada y ubicación en segundo plano para
            usar geocercas que alertan al entrar o salir de zonas seguras. Esto mejora tu seguridad permitiéndonos
            enviar notificaciones automáticas y alertas, incluso cuando la app no está en uso activo.
          </p>

          <ul>
            <li>
              <p>Ubicación Precisa: Para determinar tu posición exacta y garantizar alertas precisas.</p>
            </li>
            <li>
              <p>
                Ubicación Aproximada: Para identificar tu zona general y ahorrar batería cuando no se necesita la
                ubicación exacta.
              </p>
            </li>
            <li>
              <p>
                Ubicación en Segundo Plano: Para monitoreo continuo, incluso cuando no estés usando activamente la app.
              </p>
            </li>
          </ul>

          <p>Agradecemos tu confianza.</p>
        </div>
      </ion-card>

      <div class="container-buttons">
        <ion-button expand="block" (click)="sendResponse(false)">Denegar</ion-button>
        <ion-button expand="block" (click)="sendResponse(true)">Permitir</ion-button>
      </div>
    </div>
  </ion-content>
</div>
