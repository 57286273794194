import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
// import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { I18nService } from '@app/i18n';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { RouteService } from './@shared/services/route.service';
import { Store } from '@ngrx/store';
import { setTokenNotification } from './@shared/store/user/user.actions';
import { ScreenOrientation } from '@capacitor/screen-orientation';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private platform: Platform,
    private keyboard: Keyboard,
    private splashScreen: SplashScreen,
    // do not remove the analytics injection, even if the call in ngOnInit() is removed
    // this injection initializes page tracking through the router
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private i18nService: I18nService,
    private routeService: RouteService,
    private store: Store
  ) {}

  async ngOnInit() {
    if (this.platform.is('capacitor')) {
      await ScreenOrientation.lock({ orientation: 'portrait' });
    }

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    this.angulartics2GoogleAnalytics.startTracking();
    this.angulartics2GoogleAnalytics.eventTrack(environment.version || 'dev', {
      category: 'App initialized',
    });

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    // Cordova platform and plugins initialization
    await this.platform.ready();
    if (this.platform.is('cordova')) {
      this.configureStatusBar();
      this.pushNotificationsInit();
      this.initializeGoogleAuth();
    }
    this.onCordovaReady();
  }

  private initializeGoogleAuth() {
    GoogleAuth.initialize({
      clientId: environment.googleWebClientId,
      scopes: ['email', 'profile'],
    });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  private onCordovaReady() {
    log.debug('device ready');

    if ((window as any).cordova) {
      log.debug('Cordova init');

      this.keyboard.hideFormAccessoryBar(true);
      this.splashScreen.hide();
    }
  }

  private pushNotificationsInit(): void {
    // this.store.dispatch(setTokenNotification({ token: '23' }));

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register().then(() => {
          console.log('[PUSH NOTIFICATION]', 'registered');
        });
      } else {
        console.log('[PUSH NOTIFICATION]', 'denied');
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      console.log('[Token]', token);
      this.store.dispatch(setTokenNotification({ token: token.value }));
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.warn('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.warn('************[Push received]************' + JSON.stringify(notification));
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      console.warn('Push action performed: ' + JSON.stringify(notification));
    });
  }

  private async configureStatusBar() {
    StatusBar.setStyle({ style: Style.Light });
    await StatusBar.setOverlaysWebView({ overlay: false });
    await StatusBar.setBackgroundColor({ color: '#c7c7e5' });
  }
}
