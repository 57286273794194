import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PermissionModalComponent } from '../components/permission-modal/permission-modal.component';

type PermissionType = 'geolocation' | 'camera' | 'microphone' | 'contacts';

type LocalPermissionStatus = Record<PermissionType, boolean>;

interface PermissionDetails {
  message: string;
  imagePath: string;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private modalController = inject(ModalController);

  private permissionDetails: Record<PermissionType, PermissionDetails> = {
    geolocation: {
      message: `
        `,
      imagePath: '/assets/icons/marker-regular.svg',
    },
    camera: {
      message:
        'Para mejorar tu experiencia en nuestra app, necesitamos acceso a tu ubicación. Este permiso nos permite ofrecerte servicios relevantes cerca de ti. ¿Nos permites acceder a tu ubicación?',
      imagePath: 'assets/img/mobility.png',
    },
    microphone: {
      message:
        'Para mejorar tu experiencia en nuestra app, necesitamos acceso a tu ubicación. Este permiso nos permite ofrecerte servicios relevantes cerca de ti. ¿Nos permites acceder a tu ubicación?',
      imagePath: 'assets/img/mobility.png',
    },
    contacts: {
      message:
        'Para mejorar tu experiencia en nuestra app, necesitamos acceso a tu ubicación. Este permiso nos permite ofrecerte servicios relevantes cerca de ti. ¿Nos permites acceder a tu ubicación?',
      imagePath: 'assets/img/mobility.png',
    },
  };

  public async checkAndRequestPermission(permission: PermissionType): Promise<boolean> {
    if (permission !== 'geolocation') return true;

    let permissionStatus = this.getStatusPermission(permission);

    // Si el permiso fue explícitamente rechazado
    if (permissionStatus === false) return false;

    // Si no se ha solicitado muestra el modal
    if (permissionStatus === null) {
      return await this.presentPermissionModal(permission);
    }

    // Si el permiso ya fue otorgado
    return true;
  }

  private async presentPermissionModal(permission: PermissionType): Promise<boolean> {
    const { message, imagePath } = this.permissionDetails[permission];
    const modal = await this.modalController.create({
      component: PermissionModalComponent,
      backdropDismiss: false,
      componentProps: { imagePath, message },
      cssClass: 'modal-full-screen',
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    const accept = data.accept;
    this.setStatusPermission(permission, accept);
    return accept;
  }

  private setStatusPermission(permission: PermissionType, value: boolean): void {
    const permissions = this.getAllStatusPermissions();
    permissions[permission] = value;
    localStorage.setItem('permissions', JSON.stringify(permissions));
  }

  private getStatusPermission(permission: PermissionType): boolean | null {
    const permissions: LocalPermissionStatus = this.getAllStatusPermissions();
    return permissions[permission] ?? null;
  }

  private getAllStatusPermissions(): LocalPermissionStatus {
    return JSON.parse(localStorage.getItem('permissions') ?? '{}') as LocalPermissionStatus;
  }
}
