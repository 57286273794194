<div class="wrapper">
  <img src="/assets/img/BG-MM-3.png" alt="mujer movil" class="background-img" />
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content [fullscreen]="true">
    <ion-grid fixed>
      <img src="/assets/LOGO-ICON.png" alt="Logo" class="logo" />
      <!-- <ion-row class="ion-no-padding">
        <ion-col size="12">
          <ion-img src="./assets/logo.png" class="logo"></ion-img>
        </ion-col>
      </ion-row> -->
      <ion-row>
        <ion-col size="12" class="ion-padding-vertical">
          <img src="/assets/pleca-limpia.png" alt="" class="pleca" />
          <ion-text>
            <h2 class="ion-text-center welcome-text">
              <ion-text>Bienvenida {{ user?.name }}</ion-text>
            </h2>
          </ion-text>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <div class="cards-menu">
            <div class="cards-menu__card" (click)="openVisorusWebView()">
              <img src="/assets/menu/Menu-1.png" alt="Mi mujer movil" />
            </div>

            <div class="cards-menu__card" [routerLink]="'/safety'">
              <img src="/assets/menu/Menu-2.png" alt="Mi mujer movil" />
            </div>

            <div class="cards-menu__card" [routerLink]="'/health'">
              <img src="/assets/menu/Menu-3.png" alt="Mi mujer movil" />
            </div>

            <div class="cards-menu__card" [routerLink]="['/webview']">
              <img src="/assets/menu/Menu-4.png" alt="Mi mujer movil" />
            </div>

            <div class="cards-menu__card" [routerLink]="'/emotional'">
              <img src="/assets/menu/Menu-5.png" alt="Mi mujer movil" />
            </div>

            <div class="cards-menu__card" [routerLink]="'/nutrition'">
              <img src="/assets/menu/Menu-6.png" alt="Mi mujer movil" />
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
