import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-emotional',
  templateUrl: './emotional.component.html',
  styleUrl: './emotional.component.scss',
})
export class EmotionalComponent {
  jrContact: string = environment.jrContact;
}
