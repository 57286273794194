<ion-content [fullscreen]="false">
  <ion-grid fixed class="ion-no-margin ion-no-padding">
    <ion-row class="ion-no-margin ion-no-padding">
      <ion-col size="12" class="ion-no-margin ion-no-padding" [formGroup]="form">
        <ion-item color="primary" lines="none" [style.paddingTop]="'40px'" [style.paddingBottom]="'10px'">
          <ion-buttons slot="start">
            <ion-button (click)="cancel()">
              <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-icon src="./assets/icons/people-dress.svg" class="ion-margin-horizontal"></ion-icon>
          <ion-label>
            <h2>{{ !contact ? 'Nuevo' : 'Editar' }} Contacto Seguro</h2>
          </ion-label>
        </ion-item>

        <div class="ion-padding ion-margin-top">
          <ion-item color="light">
            <ion-label>
              <ion-text color="primary">
                <h3><b>Nombre</b></h3>
              </ion-text>
            </ion-label>
            <ion-input type="text" formControlName="name"></ion-input>
          </ion-item>
          <ion-item color="light">
            <ion-label>
              <ion-text color="primary">
                <h3><b>Teléfono</b></h3>
              </ion-text>
            </ion-label>
            <ion-input type="tel" formControlName="phoneNumber"></ion-input>
          </ion-item>
          <ion-item color="light">
            <ion-label>
              <ion-text color="primary">
                <h3><b>Correo</b></h3>
              </ion-text>
            </ion-label>
            <ion-input type="email" formControlName="email"></ion-input>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding">
      <ion-col size="12" class="">
        <ion-text color="light">
          <p>
            Al enviarle una invitación tu contacto recibirá un SMS para aceptar ser tu contacto de emergencia,
            solicítale que acepte para que puedas guardarlo.
          </p>
        </ion-text>
        <ion-item lines="none" color="primary">
          <ion-icon
            slot="start"
            [name]="contact?.invitationAccepted ? 'checkmark-circle-outline' : 'close-circle-outline'"
          ></ion-icon>
          <ion-label class="ion-text-center">
            <h2>
              {{ contact?.invitationAccepted ? 'Invitacion Aceptada' : 'Invitacion No Aceptada' }}
            </h2>
          </ion-label>
        </ion-item>
        @if(contact && !contact?.invitationAccepted) {
        <ion-button
          expand="block"
          size="large"
          color="tertiary"
          shape="round"
          class="ion-margin-top"
          (click)="shareInvitation()"
        >
          <ion-icon slot="end" name="logo-whatsapp"></ion-icon>
          Enviar invitación
        </ion-button>
        }

        <ion-button
          class="ion-margin-top"
          expand="block"
          size="large"
          color="secondary"
          shape="round"
          [disabled]="form.invalid || form.pristine"
          (click)="saveContact()"
        >
          Guardar Contacto
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
