import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { SafetyRoutingModule } from './safety-routing.module';
import { SafetyComponent } from './safety.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ModalShareLocationComponent } from './modal-share-location/modal-share-location.component';
import { SharedModule } from '@app/@shared';
// import { EmergencyButtonComponent } from './emergency-button/emergency-button.component';
// import { EmergencyComponent } from './emergency/emergency.component';

@NgModule({
  declarations: [SafetyComponent, ModalShareLocationComponent],
  imports: [CommonModule, SharedModule, TranslateModule, IonicModule, GoogleMapsModule, SafetyRoutingModule],
})
export class SafetyModule {}
