<!-- <ion-content> -->
<ion-item color="primary">
  <ion-icon slot="start" name="navigate-circle"></ion-icon>
  <ion-label>Compartir</ion-label>
</ion-item>
<ion-list>
  @for (time of timeList; track time.value) {
  <ion-item [button]="true" [detail]="false" (click)="setTime(time.value)">{{ time.label }}</ion-item>
  } @if (sharingLocation()) {
  <ion-item [button]="true" [color]="'danger'" [detail]="false" (click)="setTime(0)">Dejar de compartir</ion-item>
  }
</ion-list>
<!-- </ion-content> -->
