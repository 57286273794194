import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterRoutingModule } from './register-routing.module';
import { RegisterComponent } from './register.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { PortabilidadComponent } from './components/portabilidad/portabilidad.component';
import { FormRegisterComponent } from './components/form-register/form-register.component';

@NgModule({
  declarations: [RegisterComponent, PhoneNumberComponent, PortabilidadComponent, FormRegisterComponent],
  imports: [CommonModule, TranslateModule, IonicModule, RegisterRoutingModule, ReactiveFormsModule],
})
export class RegisterModule {}
