<div class="wrapper">
  <img src="/assets/img/BG-MM-3.png" alt="mujer movil" class="background-img" />
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
      <ion-title slot="start">
        <ion-text color="medium">
          <h1 class="ion-text-start">
            <ion-text color="light">Mujer Segura</ion-text>
          </h1>
        </ion-text>
      </ion-title>
    </ion-toolbar>
    <app-emergency-button />
  </ion-header>
  <ion-content [fullscreen]="true">
    <ion-grid fixed class="ion-no-padding">
      <ion-row class="ion-margin-bottom ion-padding-horizontal">
        <ion-col>
          <ion-chip button [routerLink]="['/safety']" [routerDirection]="'root'">
            <ion-icon [name]="'chevron-back-outline'" color="primary"></ion-icon>
            <ion-label color="primary">Regresar a Mujer Segura</ion-label>
          </ion-chip>
          <ion-chip button>
            <ion-label color="primary">Ayuda</ion-label>
            <ion-icon name="help-circle-outline" color="primary"></ion-icon>
          </ion-chip>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class="ion-no-margin ion-no-padding">
          <google-map
            mapId="mapRoute"
            height="35vh"
            width="100%"
            [options]="mapOptions"
            [center]="center"
            [zoom]="zoom"
            (mapClick)="moveMap($event)"
            (mapMousemove)="move($event)"
            #agmMap
          >
            <map-marker
              *ngFor="let marker of routeItems"
              [options]="{ title: marker.name,  }"
              [icon]="marker.active ? iconActive : iconInactive"
              [position]="{
                lat: marker.geofence.center['lat'],
                lng: marker.geofence.center['lng']
              }"
            >
            </map-marker>
            <map-circle
              *ngFor="let marker of routeItems"
              [center]="{
                lat: marker.geofence.center['lat'],
                lng: marker.geofence.center['lng']
              }"
              [radius]="marker.geofence.meters"
              [options]="marker.active ? mapCirlceActive : mapCirlceInactive"
              [title]="marker.name"
            ></map-circle>
          </google-map>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item color="primary">
            <ion-icon slot="start" src="./assets/icons/route.svg"></ion-icon>
            <ion-label>
              <h2>Mis rutas seguras</h2>
            </ion-label>
            <ion-button
              slot="end"
              shape="round"
              (click)="goToAddRoute()"
              color="light"
              fill="solid"
              [disabled]="routeItems.length >= 5"
            >
              <ion-icon slot="start" name="add"></ion-icon> Nueva
            </ion-button>
          </ion-item>
          <ion-item class="route-item" *ngFor="let item of routeItems; index as i" (click)="centerRoute(item)">
            <ion-button
              size="medium"
              [color]="item?.active ? 'success' : 'medium'"
              slot="start"
              (click)="activateDeativateRoute(item)"
            >
              <ion-icon
                slot="icon-only"
                [src]="
                  item.routeType === 'single'
                    ? './assets/icons/pin-location.svg'
                    : './assets/icons/time-watch-calendar.svg'
                "
              ></ion-icon>
            </ion-button>
            <ion-label>
              <h2 class="ion-text-wrap">{{ item.name }}</h2>
              <h3 *ngIf="item.address">{{ item.address }}</h3>
              <p>{{ item.dateText }}</p>
            </ion-label>
            <ion-chip slot="end" color="primary" mode="ios" outline="true" *ngIf="item.routeType === 'recurring'">
              <ion-label>recurrente</ion-label>
            </ion-chip>
            <ion-button [id]="'btn_' + i + '_actions'" slot="end" fill="clear" color="medium" class="actions-btn">
              <ion-icon slot="icon-only" name="ellipsis-vertical-outline"></ion-icon>
            </ion-button>
            <ion-popover [trigger]="'btn_' + i + '_actions'" triggerAction="click" #popover>
              <ng-template>
                <ion-item color="primary">
                  <ion-icon
                    slot="start"
                    [src]="
                      item.routeType === 'single'
                        ? './assets/icons/pin-location.svg'
                        : './assets/icons/time-watch-calendar.svg'
                    "
                  ></ion-icon>
                  <ion-label>{{ item.name }}</ion-label>
                </ion-item>

                <ion-item color="stable" button detail="false" class="actionbtn" (click)="activateDeativateRoute(item)">
                  <ion-label>{{ item.active ? 'Zona activa' : 'Zona desactivada' }}</ion-label>
                  <ion-checkbox slot="end" color="success" [checked]="item.active"></ion-checkbox>
                </ion-item>
                <ion-item color="stable" button detail="false" class="actionbtn" (click)="editRoute(item)">
                  <ion-icon slot="end" name="create-outline"></ion-icon>
                  <ion-label>Editar</ion-label>
                </ion-item>
                <ion-item color="stable" button detail="false" class="actionbtn" (click)="confirmDelete(item)">
                  <ion-icon slot="end" name="trash-outline"></ion-icon>
                  <ion-label>Borrar</ion-label>
                </ion-item>
              </ng-template>
            </ion-popover>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
