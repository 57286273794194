import { Component, inject } from '@angular/core';
import { FirestoreService } from '../@shared/services/firestore-service.service';
import { CredentialsService } from '@app/auth';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrl: './delete-user.component.scss',
})
export class DeleteUserComponent {
  private firebaseService = inject(FirestoreService);
  private credentialsService = inject(CredentialsService);
  private alertController = inject(AlertController);
  private loadingController = inject(LoadingController);
  private router = inject(Router);
  public email: string = '';

  constructor() {}

  public async deleteAccount() {
    const credentials = this.credentialsService.credentials;
    if (!credentials) return;

    const loading = await this.loadingController.create();
    await loading.present();

    const { username } = credentials;

    if (username !== this.email) {
      await loading.dismiss();
      const alert = await this.alertController.create({
        header: 'Atención!!',
        message: 'Email no es el mismo de tu cuenta',
      });
      alert.present();
      return;
    }

    this.firebaseService.saveDocument('deletionRequests', { email: this.email });
    const alert = await this.alertController.create({
      header: 'Atención!!',
      message: 'Tu cuenta esta en proceso de eliminación',
      buttons: [
        {
          text: 'Entendido',
          handler: () => {
            this.router.navigate(['/']);
          },
        },
      ],
    });
    alert.present();
    await loading.dismiss();
  }
}
