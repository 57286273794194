import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Credentials, CredentialsService } from './credentials.service';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Auth } from '@angular/fire/auth';
import { FirestoreService } from '@app/@shared/services/firestore-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { NewUser } from '@app/@shared/models/register.interface';
import { WrapResponse } from '@app/@shared/models/common.interface';
import { AngularFireAuth } from '@angular/fire/compat/auth';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
  userId: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private auth: Auth,
    private http: HttpClient,
    private angularFireAuth: AngularFireAuth
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    // Replace by proper authentication call
    const data = {
      username: context.username,
      token: '123456',
      userId: context.userId,
    };
    this.credentialsService.setCredentials(data);
    return of(data);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  public async logout(): Promise<void> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    await this.angularFireAuth.signOut();
  }

  async loginWithGoogle(): Promise<any> {
    // try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(this.auth, provider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;
    // The signed-in user info.
    const user = result.user;
    return {
      user,
      token,
    };
    // } catch (error: any) {
    //   // Handle Errors here.
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   // The email of the user's account used.
    //   const email = error.email;
    //   // The firebase.auth.AuthCredential type that was used.
    //   const credential = GoogleAuthProvider.credentialFromError(error);
    // }
    // "6LFxaxvy5Og0oQPc4cKMAgv7QBT2"
  }

  createNewUser(request: NewUser, tokenFB: string): Observable<WrapResponse<string>> {
    console.log(this.getHeaders(tokenFB));
    return this.http.post<WrapResponse<string>>(`${environment.urlCreateUser}`, request, {
      headers: this.getHeaders(tokenFB),
    });
  }

  getHeaders(tokenFB: string): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${tokenFB}`,
      'x-project-name': environment.projectName,
    });
  }
}
