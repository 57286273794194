import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Angulartics2Module } from 'angulartics2';

import { RouteReusableStrategy, ApiPrefixInterceptor, ErrorHandlerInterceptor, SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SafetyModule } from './safety/safety.module';
import { EmotionalModule } from './emotional/emotional.module';
import { NutritionModule } from './nutrition/nutrition.module';
import { HealthModule } from './health/health.module';
import { ProfileModule } from './profile/profile.module';

import localeEsMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { FinanceModule } from './finance/finance.module';

import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '@env/environment';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideAuth, getAuth, initializeAuth, indexedDBLocalPersistence } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { RegisterModule } from './auth/register/register.module';
import { ContactsModule } from './safety/contacts/contacts.module';
import { RouteAddModule } from './safety/route-add/route-add.module';
import { RouteModule } from './safety/route/route.module';
import { InvitationModule } from './invitation/invitation.module';
import { StoreModule } from '@ngrx/store';
import { initialState, panicAlertReducer } from './@shared/store/panic-alert/panic-alert.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PanicAlertEffects } from './@shared/store/panic-alert/panic-alert.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './@shared/store/app.reducer';
import { Capacitor } from '@capacitor/core';
import { DeleteUserModule } from './delete-user/delete-user.module';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

// Register the locale data for 'es-MX'
registerLocaleData(localeEsMX);
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot(),
    IonicModule.forRoot({ _forceStatusbarPadding: true }),
    SharedModule,
    ShellModule,
    HomeModule,
    DeleteUserModule,
    SafetyModule,
    EmotionalModule,
    NutritionModule,
    HealthModule,
    ProfileModule,
    FinanceModule,
    AuthModule,
    RegisterModule,
    RouteModule,
    RouteAddModule,
    ContactsModule,
    InvitationModule,
    Angulartics2Module.forRoot(),
    AppRoutingModule,
    StoreModule.forRoot(reducers, { initialState: { panicAlert: initialState } }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([PanicAlertEffects]), // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    InAppBrowser,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    { provide: LOCALE_ID, useValue: 'es-mx' },
    Keyboard,
    StatusBar,
    SplashScreen,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
