import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, Platform } from '@ionic/angular';
import { ActionSheetButton, ActionSheetOptions, TextFieldTypes } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

import { I18nService } from '@app/i18n';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import { VisorusService } from '@app/@shared/services/visorus.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private platform: Platform,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    private visorusService: VisorusService
  ) {}

  async showProfileActions() {
    let createdActionSheet: any;
    const buttons: ActionSheetButton[] = [
      {
        text: this.translateService.instant('Logout'),
        icon: this.platform.is('ios') ? undefined : 'log-out',
        role: 'destructive',
        handler: () => this.logout(),
      },

      {
        text: this.translateService.instant('Cancel'),
        icon: this.platform.is('ios') ? undefined : 'close',
        role: 'cancel',
      },
    ];

    // On Cordova platform language is set to the device language
    if (this.platform.is('capacitor')) {
      buttons.splice(1, 1);
    }

    const actionSheetOptions: ActionSheetOptions = {
      header: 'Cerrar Sesión',
      buttons,
    };

    createdActionSheet = await this.actionSheetController.create(actionSheetOptions);
    await createdActionSheet.present();
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  private async logout(): Promise<void> {
    if (this.platform.is('capacitor')) {
      await BackgroundGeolocation.stop();
    }
    await this.signoutGoogle();
    await this.authenticationService.logout();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  async signoutGoogle() {
    if (this.platform.is('capacitor')) {
      await GoogleAuth.signOut();
    }
  }

  get isWeb(): boolean {
    return !this.platform.is('capacitor');
  }

  private async changeLanguage() {
    const alertController = await this.alertController.create({
      header: this.translateService.instant('Change language'),
      inputs: this.i18nService.supportedLanguages.map((language) => ({
        type: 'radio' as TextFieldTypes,
        name: language,
        label: language,
        value: language,
        checked: language === this.i18nService.language,
      })),
      buttons: [
        {
          text: this.translateService.instant('Cancel'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('Ok'),
          handler: (language) => {
            this.i18nService.language = language;
          },
        },
      ],
    });
    await alertController.present();
  }

  public openVisorusWebView() {
    const user = JSON.parse(sessionStorage.getItem('userInfo') ?? '');
    if (!user) return;
    this.visorusService.generateUrlWebview(user);
  }
}
