import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CredentialsService } from './credentials.service';
import { Logger } from '@app/@shared/logger.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log(state.url);
    if (
      this.credentialsService.isAuthenticated() ||
      state.url === '/register' ||
      state.url.indexOf('invitation') > -1
    ) {
      return true;
    }
    // adding register route to unauthenticated users

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/login'], {
      queryParams: { redirect: state.url },
      replaceUrl: true,
    });
    return false;
  }
}
