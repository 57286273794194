<ion-grid fixed class="ion-no-padding">
  <ion-row>
    <ion-col size="12" class="ion-margin-vertical">
      <ion-item fill="solid" lines="none">
        <ion-label position="stacked">Ingresa el NIP</ion-label>
        <ion-input type="tel" [formControl]="nip"></ion-input>
        <ion-text color="danger" *ngIf="nip.invalid && (nip.dirty || nip.touched)">
          <small>El NIP es invalido</small>
        </ion-text>
      </ion-item>
    </ion-col>
    <ion-col size="12">
      <ion-item fill="solid" lines="none">
        <ion-label position="stacked">Teléfono actual</ion-label>
        <ion-input type="tel" [formControl]="phoneNumberCurrent"></ion-input>
        <ion-text
          color="danger"
          *ngIf="phoneNumberCurrent.invalid && (phoneNumberCurrent.dirty || phoneNumberCurrent.touched)"
        >
          <small>El teléfono actual es invalido</small>
        </ion-text>
      </ion-item>
    </ion-col>
    <ion-col size="12" class="ion-margin-vertical">
      <ion-item fill="solid" lines="none">
        <ion-label position="stacked">Teléfono Mujer Móvil</ion-label>
        <ion-input type="tel" [formControl]="phoneMujerMovil"></ion-input>
        <ion-text color="danger" *ngIf="phoneMujerMovil.invalid && (phoneMujerMovil.dirty || phoneMujerMovil.touched)">
          <small>El teléfono Mujer Móvil es invalido</small>
        </ion-text>
      </ion-item>
    </ion-col>

    <ion-col size="12" class="ion-margin-top">
      <ion-button expand="block" fill="solid" shape="round" (click)="action()" [disabled]="!formValid">
        Continuar
      </ion-button>
    </ion-col>
    <ion-col size="12" class="ion-margin-top">
      <ion-button
        color="secondary"
        expand="block"
        fill="solid"
        shape="round"
        (click)="back()"
        [style.fontWeight]="'bold'"
      >
        Regresar
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
