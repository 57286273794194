import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { PanicAlert, StorePanicAlert } from '@app/@shared/models/panicAlert.interface';
import { resetPanicAlert, setPanicAlert } from './Panic-alert.actions';

export const initialState: StorePanicAlert = {
  panicAlert: {
    createdAt: '',
    lastPosition: {
      lat: null,
      lng: null,
    },
    positions: [
      {
        lat: null,
        lng: null,
      },
    ],
    deactivatedAt: null,
    type: '',
    userId: '',
    id: null,
  },
};

export const panicAlertReducer = createReducer(
  initialState,
  on(
    setPanicAlert,
    (state, { panicAlert }): StorePanicAlert => ({
      ...state,
      panicAlert,
    })
  ),
  on(
    resetPanicAlert,
    (state): StorePanicAlert => ({
      ...state,
      panicAlert: initialState.panicAlert,
    })
  )
);

// selectors
export const selectPanicAlert = createFeatureSelector<{
  panicAlert: PanicAlert;
}>('panicAlert');
