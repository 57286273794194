import { Component, input, OnDestroy, output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PortabilidadService } from '@app/@shared/services/portabilidad.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { finalize, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-portabilidad',
  templateUrl: './portabilidad.component.html',
  styleUrl: './portabilidad.component.scss',
})
export class PortabilidadComponent implements OnDestroy {
  existPhoneNumberCurrent = input.required<boolean>();
  emitNextStep = output<void>();
  emitBack = output<void>();
  phoneValidators = [Validators.required, Validators.maxLength(10), Validators.minLength(10)];

  phoneNumberCurrent = this.fb.control(null, [...this.phoneValidators]);
  phoneMujerMovil = this.fb.control(null, [...this.phoneValidators]);
  nip = this.fb.control(null, [Validators.required]);

  $subs = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private portabilidadService: PortabilidadService,
    private alertCtrl: AlertController,
    private router: Router,
    private loadingCtrl: LoadingController
  ) {}

  ngOnDestroy(): void {
    this.$subs.next();
    this.$subs.complete();
  }

  action() {
    this.createPortability();
  }

  private async createPortability() {
    if (!this.formValid) return;

    await this.presentLoading();

    const request = {
      currentPhoneNumber: this.phoneNumberCurrent.value,
      newPhoneNumber: this.phoneMujerMovil.value,
      nip: this.nip.value,
    };
    this.portabilidadService
      .createPortability(request)
      .pipe(
        takeUntil(this.$subs),
        finalize(() => this.dismissLoading())
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.response?.status_code === 200) {
            this.createAlertSuccess();
          } else {
            this.createAlertError();
          }
        },
        error: () => {
          this.createAlertError();
        },
      });
  }

  back() {
    this.emitBack.emit();
  }

  get formValid() {
    return this.phoneNumberCurrent.valid && this.phoneMujerMovil.valid && this.nip.valid;
  }

  async createAlertSuccess(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Portabilidad',
      message:
        'Se creo exitosamente la solicitud de portabilidad, debes esperar un lapso de 24 horas para que se procese la solicitud, te llegara un SMS con la activación de tu número telefónico.',
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            this.router.navigate(['/login']);
          },
        },
      ],
    });
    await alert.present();

    alert.onDidDismiss().then(() => {
      this.router.navigate(['/login']);
    });
  }

  async createAlertError(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Portabilidad',
      message: 'Hubo un error al crear la solicitud de portabilidad, vuelve a intentarlo',
      buttons: ['Aceptar'],
    });
    await alert.present();
  }

  async presentLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Creando solicitud de portabilidad...',
    });
    await loading.present();
  }

  async dismissLoading() {
    await this.loadingCtrl.dismiss();
  }
}
