import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import BackgroundGeolocation, {
  Geofence,
  Location,
  GeofenceEvent,
} from '@transistorsoft/capacitor-background-geolocation';
import { GoogleMap } from '@angular/google-maps';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { CredentialsService } from '@app/auth';
import { RouteService } from '@app/@shared/services/route.service';
import { SafetyMapCircle, SafetyRoute } from '@app/@shared/models/satefy.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  defaultCenter,
  emergencyMapOptions,
  iconActive,
  iconInactive,
  mapCirlceActive,
  mapCirlceInactive,
} from '@app/@shared/constants/map-config';
import { lastValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { getPanicAlert } from '@app/@shared/store/panic-alert/Panic-alert.actions';
import { PermissionsService } from '@app/@shared/services/permissions.service';

/// Polyline points

/// Active Geofence Map, keyed by Geofence.identifier
const ACTIVE_GEOFENCES = new Map<string, Geofence>();

@UntilDestroy()
@Component({
  selector: 'app-safety',
  templateUrl: './safety.component.html',
  styleUrl: './safety.component.scss',
})
export class SafetyComponent implements OnInit {
  @ViewChild('mapSafety') mapSafety!: GoogleMap;

  display: any;

  iconActive = iconActive();
  iconInactive = iconInactive();

  center = defaultCenter();
  mapOptions = emergencyMapOptions();
  mapCirlceActive = mapCirlceActive();

  zoom = 14;
  radius = 400;

  // markers
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];

  mapCircleList: SafetyMapCircle[] = [];

  enabled!: boolean;
  stationaryLocation!: Location;
  // Flag when user is creating a polygon on map.
  isCreatingPolygon!: boolean;

  showMap = false;
  currentLocation!: google.maps.LatLngLiteral;
  activeRouteId!: string;
  isMoving!: boolean;
  sharingLocation = false;
  safeRouteList: SafetyRoute[] = [];
  allSafeRouteList: SafetyRoute[] = [];

  // interval
  intervalEachMinute!: NodeJS.Timeout | null;

  // Background plugin
  timeInterval: number = 15000;

  safetyActiveRoute!: SafetyRoute | null;

  constructor(
    private modalCtrl: ModalController,
    private zone: NgZone,
    private credentialsService: CredentialsService,
    private routeService: RouteService,
    private toastController: ToastController,
    private platform: Platform,
    private store: Store,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getPanicAlert());
  }

  async ionViewDidEnter(): Promise<void> {
    const isAcceptGeolocation = await this.permissionsService.checkAndRequestPermission('geolocation');

    if (!isAcceptGeolocation) return;

    this.checkLocation();
    this.getActiveRoutes();

    if (this.platform.is('capacitor')) {
      BackgroundGeolocation.getGeofences().then((geofences) => console.log('[Geofences List]', geofences));
    }

    this.getCurrentPosition().then((position) => this.setCenter(position.lat, position.lng, true));
  }

  ionViewDidLeave() {
    // this.mapSafety.ngOnDestroy();
  }

  async presentToast(message: string, position: 'top' | 'middle' | 'bottom' = 'top') {
    const toast = await this.toastController.create({
      message,
      duration: 10000,
      position,
      color: 'secondary',
    });

    await toast.present();
  }

  async getCurrentPosition(): Promise<google.maps.LatLngLiteral> {
    const coordinates = await Geolocation.getCurrentPosition();
    const lat = coordinates.coords.latitude;
    const lng = coordinates.coords.longitude;
    return { lat, lng };
  }

  private async findZoomLevel(places: SafetyRoute[]): Promise<void> {
    const bounds = new google.maps.LatLngBounds();
    places.forEach((item) => {
      bounds.extend(new google.maps.LatLng(item.geofence.center['lat'], item.geofence.center['lng']));
    });

    const currentLocation = await this.getCurrentPosition();
    bounds.extend(new google.maps.LatLng(currentLocation.lat, currentLocation.lng));

    // Make sure the map object is already initialized
    if (this.mapSafety.googleMap) {
      this.mapSafety.googleMap.fitBounds(bounds);
    }
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = event.latLng.toJSON();
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  addMarker(lat: number, lng: number) {
    this.markerPositions.push({ lat, lng });
  }

  mapLoaded(event: google.maps.Map) {
    // this.map = event;
    console.log('[mapLoaded]', event);
    // this.getCurrentPosition().then((position) => {
    //   console.log(position);
    //   this.setCenter(position.lat, position.lng, true);
    // });
  }

  /// Setup BackgroundGeolocation event-listeners.
  private async configureBackgroundGeolocation() {
    try {
      // this.startBackgroundGeolocation();
      await BackgroundGeolocation.ready({
        // transistorAuthorizationToken: token,
        reset: false,
        debug: true,
        locationAuthorizationRequest: 'Always',
        logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
        backgroundPermissionRationale: {
          title: "Allow {applicationName} to access this device's location even when closed or not in use.",
          message:
            'This app collects location data to enable recording your trips to work and calculate distance-travelled.',
          positiveAction: 'Change to "{backgroundPermissionOptionLabel}"',
          negativeAction: 'Cancel',
        },
        distanceFilter: 10,
        stopTimeout: 1,
        stopOnTerminate: false,
        startOnBoot: true,
        enableHeadless: true,
        autoSync: true,
        // maxDaysToPersist: 14,
      });

      BackgroundGeolocation.onEnabledChange(this.onEnabledChange.bind(this));
      BackgroundGeolocation.onLocation(this.onLocation.bind(this));
      BackgroundGeolocation.onGeofence(this.onGeofence.bind(this));
      this.onWatchPosition();

      const state = await BackgroundGeolocation.getState();
      console.log('[state]', state);
      if (state.enabled) {
        return;
        // BackgroundGeolocation.stop();
        // await BackgroundGeolocation.removeListeners();
      }

      // Store the plugin state onto ourself for convenience.
      console.log('- BackgroundGeolocation is ready: ', state);
      await BackgroundGeolocation.start();
    } catch (error) {
      console.log(error);
    }
  }

  /// @event
  onEnabledChange(enabled: boolean) {
    this.enabled = enabled;
  }

  /// @event
  async onLocation(location: Location) {
    this.isMoving = location.is_moving;
  }

  /// @event
  async onGeofence(event: GeofenceEvent) {
    if (event.action === 'ENTER') {
      await this.presentToast('Has llegado a tu destino', 'top');
      const routeId = event?.extras?.['routeId'] as string;
      console.log('[ENTER]');
      console.log('[event identifier]', routeId);
      console.log('[mapCircleList]', this.mapCircleList);
      // if (routeId) this.updateGeolocation();
      for (const route of this.mapCircleList) {
        if (route.routeId === routeId) {
          console.log('[enter geofence change color route]', routeId);
          this.zone.run(() => {
            route.options = mapCirlceInactive();
          });
        }
      }

      this.currentLocation = {
        lat: event.location.coords.latitude,
        lng: event.location.coords.longitude,
      };

      await BackgroundGeolocation.stop();
      await BackgroundGeolocation.removeListeners();
      await BackgroundGeolocation.removeGeofences();
    }

    // console.log('onGeofence', event);
    // const geofence = await BackgroundGeolocation.getGeofence(event.identifier);
    // const location = event.location;
    // const center = {
    //   latitude: geofence.latitude,
    //   longitude: geofence.longitude,
    // };
    // const radius = geofence.radius as number;

    // POLYLINE_PATH.push({
    //   lat: location.coords.latitude,
    //   lng: location.coords.longitude,
    // });

    // let color, iconColor;
    // if (event.action === 'ENTER') {
    //   color = 'green';
    //   iconColor = 'green';
    // } else if (event.action === 'DWELL') {
    //   color = 'gold';
    //   iconColor = 'amber';
    // } else {
    //   color = 'red';
    //   iconColor = 'red';
    // }

    // let iconIndex =
    //   location.coords.heading >= 0
    //     ? Math.round(location.coords.heading / 10)
    //     : 0;
    // if (iconIndex > 36) iconIndex = 0;

    // MARKERS.geofenceEventMarkers.push(
    //   await this.map.addMarker({
    //     zIndex: 100,
    //     coordinate: {
    //       lat: location.coords.latitude,
    //       lng: location.coords.longitude,
    //     },
    //     iconUrl: `assets/imgs/markers/location-arrow-${iconColor}-${iconIndex}.png`,
    //     iconSize: {
    //       width: 20,
    //       height: 20,
    //     },
    //     iconAnchor: {
    //       x: 10,
    //       y: 10,
    //     },
    //   })
    // );

    // const bearing = getBearing(center, location.coords);
    // const edgeCoordinate = computeOffsetCoordinate(center, radius, bearing);

    // console.log({ edgeCoordinate });
    // if (edgeCoordinate) {
    //   this.addMarker(edgeCoordinate.latitude, edgeCoordinate.longitude);
    // }

    // MARKERS.geofenceEventMarkers.push(
    //   await this.map.addMarker({
    //     zIndex: 500,
    //     coordinate: {
    //       lat: edgeCoordinate.latitude,
    //       lng: edgeCoordinate.longitude,
    //     },
    //     isFlat: true,
    //     iconUrl: `assets/imgs/markers/geofence-event-edge-circle-${event.action.toLowerCase()}.png`,
    //     iconSize: {
    //       width: 10,
    //       height: 10,
    //     },
    //     iconAnchor: {
    //       x: 5,
    //       y: 5,
    //     },
    //   })
    // );
    // MARKERS.geofenceEventPolylines = MARKERS.geofenceEventPolylines.concat(
    //   await this.map.addPolylines([
    //     {
    //       geodesic: true,
    //       zIndex: 99,
    //       strokeColor: COLORS.black,
    //       strokeOpacity: 1.0,
    //       strokeWeight: 2,
    //       path: [
    //         {
    //           lat: location.coords.latitude,
    //           lng: location.coords.longitude,
    //         },
    //         {
    //           lat: edgeCoordinate.latitude,
    //           lng: edgeCoordinate.longitude,
    //         },
    //       ],
    //     },
    //   ])
    // );
  }

  private setCenter(lat: number, lng: number, center = false) {
    this.zone.run(() => {
      this.currentLocation = { lat, lng };
      if (center) {
        this.center = { lat, lng };
      }
    });
  }

  async addGeofence(config: Geofence, idRoute: string, safeRouteId: string, isDisabled = false): Promise<void> {
    try {
      console.log('add to google maps');
      this.addGeofenceGoogleMap(config.latitude!, config.longitude!, config.radius!, idRoute, safeRouteId, isDisabled);

      if (this.platform.is('capacitor')) {
        const success = await BackgroundGeolocation.addGeofence({
          ...config,
          notifyOnEntry: true,
          notifyOnExit: false,
          notifyOnDwell: false,
          loiteringDelay: 30000,
        });
        console.log('Geofence added', success);
      }
    } catch (error) {
      console.log('[error addGeofence]', error);
    }
  }

  private addGeofenceGoogleMap(
    lat: number,
    lng: number,
    radius: number,
    id: string,
    safeRouteId: string,
    isDisable = false
  ): void {
    let mapCircleOptions = isDisable ? mapCirlceInactive() : mapCirlceActive();

    console.log('[isDisable]', isDisable, mapCircleOptions);
    const circle = new google.maps.Circle({
      center: {
        lat,
        lng,
      },
      radius,
      strokeWeight: 2,
      draggable: false,
      ...mapCircleOptions,
    });

    this.mapCircleList.push({
      routeId: id,
      safeRouteId,
      circle,
      options: mapCircleOptions,
    });

    console.log(this.mapCircleList);
  }

  private async getActiveRoutes(): Promise<void> {
    const userId = this.getUserId();
    await this.getCurrentRoute();

    this.getAllRoutes(userId);

    // this.routeService
    //   .getActiveRoutes(userId)
    //   .pipe(untilDestroyed(this))
    //   .subscribe({
    //     next: async (response) => {
    //       console.log(response);
    //       const routes = response.data.safetyActiveRoutes;
    //       this.safeRouteList = routes;
    //       console.log('[Active routes]', routes);

    //       // this.getAllRoutes(userId);

    //       for (const route of routes) {
    //         if (route.id !== this.activeRouteId) {
    //           this.addGeofenceGoogleMap(
    //             route.geofence.center['lat'],
    //             route.geofence.center['lng'],
    //             route.geofence.meters,
    //             route.id as string,
    //             route.id as string,
    //             true
    //           );
    //         }
    //       }

    //       if (routes.length > 0) {
    //         this.findZoomLevel(routes);
    //       }

    // if (this.safetyActiveRoute) {
    // if (this.platform.is('mobile')) {
    //   await this.configureBackgroundGeolocation();
    // }
    // console.log('background geolocation started');
    // this.activeRouteId = this.safetyActiveRoute.id as string;
    // this.addGeofence(
    //   {
    //     identifier: this.safetyActiveRoute.id as string,
    //     radius: this.safetyActiveRoute.geofence.meters,
    //     latitude: this.safetyActiveRoute.geofence.center['lat'],
    //     longitude: this.safetyActiveRoute.geofence.center['lng'],
    //     extras: {
    //       routeId: this.safetyActiveRoute.id as string,
    //     },
    //   },
    //   this.activeRouteId as string,
    //   this.safetyActiveRoute.safetyRouteId as string,
    //   false
    // );
    // }
    // },
    // });
  }

  private async getCurrentRoute(): Promise<void> {
    const resp = await lastValueFrom(this.routeService.getCurrentSafetyActiveRoute(this.getUserId()));
    this.safetyActiveRoute = resp?.['data']?.['safetyActiveRoute'] || null;

    console.log('[currentRoute]', this.safetyActiveRoute);
    if (this.safetyActiveRoute) {
      if (this.platform.is('capacitor')) {
        await this.configureBackgroundGeolocation();
        console.info('background geolocation started');
      }
      this.activeRouteId = this.safetyActiveRoute.id as string;
      this.addGeofence(
        {
          identifier: this.safetyActiveRoute.id as string,
          radius: this.safetyActiveRoute.geofence.meters,
          latitude: this.safetyActiveRoute.geofence.center['lat'],
          longitude: this.safetyActiveRoute.geofence.center['lng'],
          extras: {
            routeId: this.safetyActiveRoute.id as string,
          },
        },
        this.activeRouteId as string,
        this.safetyActiveRoute.safetyRouteId as string,
        false
      );
    } else {
      this.safetyActiveRoute = null;
      if (this.platform.is('capacitor')) {
        BackgroundGeolocation.stop();
        await BackgroundGeolocation.removeListeners();
      }
    }
  }

  private getAllRoutes(userId: string): void {
    this.routeService
      .getSafetyRoutes(userId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.allSafeRouteList = response?.data?.safetyRoutes;
          console.log('[allSafeRouteList]', this.allSafeRouteList);
          for (const route of this.allSafeRouteList) {
            if (route.id !== this.safetyActiveRoute?.safetyRouteId) {
              this.addGeofenceGoogleMap(
                route.geofence.center['lat'],
                route.geofence.center['lng'],
                route.geofence.meters,
                route.id as string,
                route.id as string,
                true
              );
            }
          }

          if (this.allSafeRouteList.length > 0) this.findZoomLevel(this.allSafeRouteList);
        },
      });
  }

  private async updateGeolocation(from: string): Promise<void> {
    console.log([from]);
    const currentPosition = await Geolocation.getCurrentPosition();
    const position = {
      lat: currentPosition.coords.latitude,
      lng: currentPosition.coords.longitude,
    };
    const resp = lastValueFrom(this.routeService.updatePositionUser(position));

    console.log(resp);
  }

  private getUserId(): string {
    return this.credentialsService?.credentials?.userId as string;
  }

  private onWatchPosition() {
    BackgroundGeolocation.watchPosition(
      (location: Location) => {
        if (!this.intervalEachMinute) {
          const { latitude: lat, longitude: lng } = location.coords;
          this.setCenter(lat, lng);
          // Se actualiza cada 30 segundos cuando va en trayecto
          if (this.activeRouteId) {
            this.updateGeolocation('onWatchPosition');
          }
        }
      },
      (error) => {
        console.log('[errorWatching] -', error);
      },
      {
        interval: this.timeInterval,
        persist: true,
      }
    );
  }

  checkLocation(timeEnd?: number): void {
    const onTime = this.isLocationOnTime(timeEnd ?? (this.credentialsService?.getTimeLocationEnd() as number));
    console.log('[onTime]', onTime);

    this.sharingLocation = onTime;

    if (onTime && !this.intervalEachMinute) {
      this.getCurrentPosition().then(({ lat, lng }) => this.setCenter(lat, lng));
      this.updateGeolocation('checkLocation');
      this.startTimeoutEachMinute();
    }
    if (!onTime) {
      this.clearIntervalSharing();
    }
  }

  private startTimeoutEachMinute(): void {
    this.intervalEachMinute = setInterval(() => {
      const onTime = this.isLocationOnTime(this.credentialsService?.getTimeLocationEnd() as number);

      if (onTime) {
        this.sharingLocation = onTime;
        this.updateGeolocation('startTimeoutEachMinute');
        this.getCurrentPosition().then((position) => this.setCenter(position.lat, position.lng));
      }

      if (!onTime) {
        this.clearIntervalSharing();
      }

      console.log('[sharingLocation]', this.sharingLocation);
    }, this.timeInterval);
  }

  clearIntervalSharing() {
    clearInterval(this.intervalEachMinute as NodeJS.Timeout);
    this.intervalEachMinute = null;
    this.sharingLocation = false;
  }

  private isLocationOnTime(endTime: number): boolean {
    // console.log('[endTime]', new Date(endTime).toISOString());
    // console.log('[currentTime]', new Date().toISOString());
    if (!endTime) return false;
    const currentTime = new Date().getTime();
    return endTime > currentTime;
  }

  openInfoWindow(circleData: SafetyMapCircle): void {
    const route = this.allSafeRouteList.find((x) => x.id === circleData.safeRouteId);

    console.log(route);
    console.log(circleData);
    console.log('[all', this.allSafeRouteList);
    console.log('[active', this.safeRouteList);

    let infoWindow = new google.maps.InfoWindow({
      content: `<p style="color: #644b78; margin-bottom: 5px; font-size: 17px; font-weight: 700; margin-top:0">${route?.name}</p>
      <p style="margin-top: 0">${route?.address}</p>`,
      position: circleData.circle.getCenter(),
    });

    infoWindow.open({
      anchor: circleData.circle,
      map: this.mapSafety.googleMap,
      shouldFocus: false,
    });
  }
}
