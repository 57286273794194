import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'about',
      loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'safety',
      loadChildren: () => import('./safety/safety.module').then((m) => m.SafetyModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'emotional',
      loadChildren: () => import('./emotional/emotional.module').then((m) => m.EmotionalModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'health',
      loadChildren: () => import('./health/health.module').then((m) => m.HealthModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'nutrition',
      loadChildren: () => import('./nutrition/nutrition.module').then((m) => m.NutritionModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'profile',
      loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    },
  ]),
  // Shell.childRoutes([
  //   {
  //     path: 'finance',
  //     loadChildren: () => import('./finance/finance.module').then((m) => m.FinanceModule),
  //   },
  // ]),
  Shell.childRoutes([
    {
      path: 'safety/route',
      loadChildren: () => import('./safety/route/route.module').then((m) => m.RouteModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'safety/contacts',
      loadChildren: () => import('./safety/contacts/contacts.module').then((m) => m.ContactsModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'safety/route-add',
      loadChildren: () => import('./safety/route-add/route-add.module').then((m) => m.RouteAddModule),
    },
  ]),
  Shell.childRoutes([
    {
      path: 'webview',
      loadChildren: () => import('./webview/webview.module').then((m) => m.WebviewModule),
    },
  ]),
  // Fallback when no prior route is matched
  // Shell.childRoutes([
  {
    path: 'invitation',
    loadChildren: () => import('./invitation/invitation.module').then((m) => m.InvitationModule),
  },
  Shell.childRoutes([
    {
      path: 'delete-user',
      loadChildren: () => import('./delete-user/delete-user.module').then((m) => m.DeleteUserModule),
    },
  ]),
  // ]),
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
