<ion-content [fullscreen]="true">
  <google-map
    height="100%"
    width="100%"
    [options]="mapOptions"
    [center]="center"
    [zoom]="zoom"
    (mapClick)="moveMap($event)"
    (mapMousemove)="move($event)"
  >
  </google-map>
</ion-content>
