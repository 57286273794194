import { Injectable } from '@angular/core';
import { FirestoreService } from '@app/@shared/services/firestore-service.service';
import { Contact } from '../models/contacts.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private fsService: FirestoreService) {}

  getContacts(userId: string): Promise<any> {
    return this.fsService.getDocQuery('contacts', 'userId', '==', userId);
  }

  createContact(contact: Contact): Promise<any> {
    return this.fsService.saveDocument('contacts', contact);
  }

  updateContact(contact: Contact, id: string): Promise<any> {
    return this.fsService.updateDocument('contacts', id, contact);
  }

  deleteContact(id: string): Promise<any> {
    return this.fsService.deleteDocument('contacts', id);
  }

  acceptInvitation(id: string): Promise<any> {
    return this.fsService.updateDocument('contacts', id, {
      invitationAccepted: true,
      lastSeen: new Date(),
    });
  }
}
