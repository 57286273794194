<div class="wrapper">
  <img src="/assets/img/BG-MM-3.png" alt="mujer movil" class="background-img" />

  <ion-content [fullscreen]="true">
    <ion-grid fixed style="display: flex; height: 100%; width: 80%">
      <div style="margin: auto; text-align: center">
        <ion-text>
          <h1>Para eliminar tu cuenta confirma el Correo</h1>
        </ion-text>
        <ion-item>
          <ion-input [(ngModel)]="email" type="email" placeholder="Correo"></ion-input>
        </ion-item>
        <ion-button class="ion-margin-top" (click)="deleteAccount()" expand="block" shape="round">
          Eliminar Cuentas
        </ion-button>
      </div>
    </ion-grid>
  </ion-content>
</div>
