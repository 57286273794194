<ion-grid fixed>
  <ion-row>
    <ion-col size="12">
      <div class="ion-padding-horizontal register-form" [formGroup]="form">
        <ion-item class="ion-margin-bottom" shape="round">
          <ion-label position="stacked">Nombre</ion-label>
          <ion-input type="text" formControlName="name"></ion-input>
        </ion-item>
        <ion-item class="ion-margin-bottom" shape="round">
          <ion-label position="stacked">Apellidos</ion-label>
          <ion-input type="text" formControlName="lastname"></ion-input>
        </ion-item>
        <ion-item class="ion-margin-bottom" shape="round" *ngIf="form.get('email')?.enabled">
          <ion-label position="stacked">Email</ion-label>
          <ion-input type="email" formControlName="email"></ion-input>
        </ion-item>

        <ion-item class="ion-margin-bottom" shape="round" *ngIf="form.get('password')?.enabled">
          <ion-label position="stacked">Contraseña</ion-label>
          <ion-input type="password" formControlName="password"></ion-input>
        </ion-item>
        <ion-item class="ion-margin-bottom inner-item" shape="round" *ngIf="form.get('password')?.enabled">
          <ion-label position="stacked">Repetir contraseña</ion-label>
          <ion-input type="password" formControlName="repeatedPassword"></ion-input>
          <ion-note slot="error" *ngIf="passwordRepeated">Las contraseñas no coinciden</ion-note>
        </ion-item>
        <ion-item id="open-modal" class="ion-margin-bottom" shape="round" *ngIf="form.get('birthday')?.enabled">
          <ion-label [style.marginTop]="'15px'" [style.marginBottom]="'15px'">Fecha de nacimiento</ion-label>
          <ion-text color="primary" slot="end">
            <span>{{ form.get('birthday')?.value | date }}</span>
          </ion-text>
          <!-- <ion-note slot="end"></ion-note> -->
        </ion-item>

        <ion-modal trigger="open-modal" [cssClass]="'bottom-end'">
          <ng-template>
            <ion-datetime
              presentation="date"
              size="cover"
              [showDefaultButtons]="true"
              formControlName="birthday"
            ></ion-datetime>
          </ng-template>
        </ion-modal>

        <ion-button
          [disabled]="form.invalid"
          color="primary"
          size="large"
          expand="block"
          shape="round"
          translate
          class="ion-margin-top"
          (click)="submitAction()"
        >
          Continua tu registro
        </ion-button>
      </div>

      <!-- <div class="login-container" *ngIf="!uidUser">
        <hr />
        <p class="ion-no-margin ion-text-center">
          <ion-text color="medium" translate> o regístrate con</ion-text>
        </p>
        <ion-button color="google" expand="block" shape="round" translate (click)="loginWithGoogle()">
          <ion-icon slot="start" name="logo-google"></ion-icon>
          Login with Google
        </ion-button>
        <ion-button color="facebook" expand="block" shape="round" translate>
          <ion-icon slot="start" name="logo-facebook"></ion-icon>
          Login with Facebook
        </ion-button>
        <ion-button color="apple" expand="block" shape="round" translate>
          <ion-icon slot="start" name="logo-apple"></ion-icon>
          Login with Apple
        </ion-button>
        <hr />
      </div> -->
      <ion-button color="secondary" expand="block" shape="round" fill="solid" [routerLink]="['/']" translate>
        Salir
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
