<ion-header>
  <ion-toolbar [style.paddingTop]="'10px'">
    <ion-chip (click)="deleteSchedule()" color="danger" *ngIf="day">Eliminar horario</ion-chip>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="cancel()">
        <ion-icon slot="icon-only" size="large" color="primary" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="light" [formGroup]="form">
  <ion-row class="ion-justify-content-center">
    <ion-col size="9">
      <ion-datetime
        id="hora-schedule"
        locale="es-ES"
        hourCycle="h11"
        presentation="time"
        [preferWheel]="false"
        display-format="HH:mm"
        formControlName="time"
      >
        <span slot="time-label">Hora</span>
      </ion-datetime>
    </ion-col>
  </ion-row>
  <ion-row class="ion-margin-bottom">
    <ion-col size="12">
      <ion-item>
        <ion-text color="primary">
          <p [style.marginBottom]="'0'">
            <small>Selecciona el tiempo de espera:</small>
          </p>
        </ion-text>
      </ion-item>
      <ion-list>
        <ion-radio-group formControlName="waitTimeMinutes">
          @for (time of timeList; track time.value) {
          <ion-item>
            <ion-label>{{ time.name }}</ion-label>
            <ion-radio slot="end" [value]="time.value"></ion-radio>
          </ion-item>
          }
        </ion-radio-group>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12" class="ion-margin-top ion-padding-horizontal">
      <ion-button expand="block" color="secondary" shape="round" [disabled]="form.invalid" (click)="setSchedule()">
        <b>Guardar</b>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
