import { Component, OnInit } from '@angular/core';

import { FirestoreService } from '@app/@shared/services/firestore-service.service';
import { CredentialsService } from '@app/auth';
import { CryptService } from '@app/@shared/services/crypt.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { RouteService } from '@app/@shared/services/route.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LoadingController } from '@ionic/angular';
import { VisorusService } from '@app/@shared/services/visorus.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  user: any;

  constructor(
    private cryptService: CryptService,
    private fsService: FirestoreService,
    private credentialService: CredentialsService,
    private routeService: RouteService,
    private angularFireAuth: AngularFireAuth,
    private loadingController: LoadingController,
    private visorusService: VisorusService
  ) {}

  public async ngOnInit() {
    const loading = await this.loadingController.create();
    await loading.present();
    try {
      await this.setCredentials();
      await this.cryptService.getEncryptionKey();
      await this.getUserInformation();
      await loading.dismiss();
    } catch (error) {
      console.error(error);
      await loading.dismiss();
    }
  }

  async getUserInformation(): Promise<void> {
    try {
      const credential = this.credentialService.credentials;
      const user = await this.fsService.getDocument('user', credential!.userId);
      this.user = user;
      sessionStorage.setItem('userInfo', JSON.stringify(this.user));
    } catch (error) {}
  }

  private async setCredentials() {
    const user = await firstValueFrom(this.angularFireAuth.user);
    const token = await firstValueFrom(this.angularFireAuth.idToken);

    if (!user || !token) return;

    await this.getJWTMobility(token);
    this.credentialService.setCredentials({
      username: user.email as string,
      token,
      userId: user.uid,
    });
  }

  private async getJWTMobility(tokenFirebase: string) {
    try {
      const resp = await lastValueFrom(this.routeService.getJwtMobility(tokenFirebase));
      this.credentialService.setJwtMobility(resp.data.token);
    } catch (error) {
      console.error('errorr', JSON.stringify(error));
    }
  }

  public openVisorusWebView() {
    this.visorusService.generateUrlWebview(this.user);
  }
}
