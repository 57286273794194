import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { getPanicAlert, setPanicAlert } from './Panic-alert.actions';
import { RouteService } from '@app/@shared/services/route.service';

@Injectable()
export class PanicAlertEffects {
  getPanicAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPanicAlert),
      exhaustMap(() =>
        this.routeService.getPanicAlert().pipe(
          map((panicAlert) => setPanicAlert({ panicAlert: panicAlert.data.panicAlert })),
          catchError(() => EMPTY)
        )
      )
    );
  });

  constructor(private actions$: Actions, private routeService: RouteService) {}
}
