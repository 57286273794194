import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { last } from 'cypress/types/lodash';
import { es } from 'date-fns/locale';
import { ContactsAddComponent } from '../contacts-add/contacts-add.component';
import { AlertController, IonPopover, ModalController, Platform } from '@ionic/angular';
import { ContactsService } from '@app/@shared/services/contacts.service';
import { Contact } from '@app/@shared/models/contacts.interface';
import { Share } from '@capacitor/share';
import { environment } from '@env/environment';
import { CredentialsService } from '@app/auth';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChildren('popover') popover!: QueryList<IonPopover>;

  dateFnsOptions = {
    locale: es,
    addSuffix: true,
  };
  iconActive = {
    url: './assets/pin-secondary.png', // url
    scaledSize: new google.maps.Size(30, 30), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(15, 28), // anchor
  };
  contacts: Contact[] = [];
  center: google.maps.LatLngLiteral = {
    lat: 19.432608,
    lng: -99.133209,
  };
  mapOptions: google.maps.MapOptions = {
    styles: [
      {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [
          {
            color: '#000000',
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
          {
            hue: '#ff0000',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#ff0000',
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#000000',
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'off',
          },
          {
            color: '#ff0000',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#9b30f2',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#644b78',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape.natural.landcover',
        elementType: 'all',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'landscape.natural.terrain',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#CCC2D3',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
          {
            color: '#d3d4f3',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            color: '#000000',
          },
          {
            weight: '0.01',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            visibility: 'simplified',
          },
          {
            weight: '0.01',
          },
        ],
      },
      {
        featureType: 'transit.station.bus',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '#eeeeff',
          },
          {
            visibility: 'on',
          },
        ],
      },
    ],
    controlSize: 36,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_TOP,
    },
    streetViewControlOptions: {
      position: google.maps.ControlPosition.BLOCK_START_INLINE_END,
    },
    heading: 350,
    mapTypeId: 'roadmap',
    disableDefaultUI: true,
  };
  minVisibleRadius = 100; // Minimum visible radius in meters

  sharingLocation = false;
  zoom = 14;

  loading: boolean = true;

  // {
  //   name: 'Ana Ramirez',
  //   phone: '555-555-5555',
  //   location: { lat: 19.4254, lng: -99.14541 },
  //   lastSeen: new Date(new Date().getTime() - 15 * 60000),
  //   accepted: false,
  // },
  constructor(
    private modalCtrl: ModalController,
    private contactsService: ContactsService,
    private alertController: AlertController,
    private platform: Platform,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit(): void {
    this.getContactList();
  }

  ngOnDestroy(): void {}

  // ngAfterViewInit() {
  //   this.findZoomLevel();
  // }

  private async getContactList(): Promise<void> {
    try {
      let contacts = await this.contactsService.getContacts(this.getUserId());
      contacts = contacts.map((contact: any) => ({
        id: contact.id,
        ...contact.data,
      }));
      console.log(contacts);
      this.contacts = contacts;
      this.findZoomLevel();
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  private findZoomLevel(): void {
    if (this.contacts.length === 0) return;
    const bounds = new google.maps.LatLngBounds();
    this.contacts.forEach((item) => {
      if (!item.lastLocation) return;
      bounds.extend(new google.maps.LatLng(item.lastLocation!.lat, item.lastLocation!.lng));
    });

    // Make sure the map object is already initialized
    if (this.map.googleMap && !bounds.isEmpty()) {
      this.map.googleMap.fitBounds(bounds);
    }
  }
  setCenterAndZoom(contact: Contact) {
    this.center = contact.lastLocation!;
    if (this.map.googleMap) {
      this.map.googleMap.setCenter(contact.lastLocation!);
      this.map.googleMap.setZoom(14);
    }
  }
  async openModal(contact?: Contact) {
    if (this.contacts.length >= 3) return;
    this.popover?.forEach((item) => {
      item.dismiss();
    });
    // this.popover.dismiss();
    const modal = await this.modalCtrl.create({
      component: ContactsAddComponent,
      componentProps: {
        contact,
      },
      cssClass: 'modal-full-screen',
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      this.getContactList();
    }
  }

  private async deleteContact(id: string) {
    try {
      this.contacts = this.contacts.filter((contact) => contact['id'] !== id);
      await this.contactsService.deleteContact(id);
    } catch (error) {
      console.log(error);
    }
  }

  async confirmDelete(contact: Contact) {
    this.popover?.forEach((item) => {
      item.dismiss();
    });
    const alert = await this.alertController.create({
      header: `Borrar ${contact.name}`,
      subHeader: '¿Estás seguro de que deseas borrar este contacto?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Borrar',
          role: 'confirm',
          handler: () => {
            this.deleteContact(contact['id']!);
          },
        },
      ],
    });

    await alert.present();
  }

  async shareInvitation(id: string): Promise<void> {
    if (!this.platform.is('capacitor') || !id) return;

    await Share.share({
      title: '',
      text: 'Hola! Te invito a unirte a mi red de contactos seguridad \nIngresa a la siguiente liga para aceptar\n',
      url: `${environment.urlMujerMovil}/invitation/${id}`,
      dialogTitle: '',
    });
  }
  private getUserId(): string {
    return this.credentialsService?.credentials?.userId as string;
  }
}
