import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timeList } from '@app/@shared/constants/time';
import { ScheduleDate } from '@app/@shared/models/satefy.interface';
import { AlertController, ModalController } from '@ionic/angular';
import { format } from 'date-fns';

@Component({
  selector: 'app-modal-route-schedule',
  templateUrl: './modal-route-schedule.component.html',
  styleUrls: ['./modal-route-schedule.component.scss'],
})
export class ModalRouteScheduleComponent implements OnInit {
  @Input() day!: ScheduleDate;

  timeList = timeList();
  form!: FormGroup;

  constructor(private fb: FormBuilder, private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.formInit();
    this.getCurrentTime();
    this.setEditTime();
    console.log(this.day);
  }

  private formInit() {
    this.form = this.fb.group({
      time: [null, Validators.required],
      waitTimeMinutes: ['30', Validators.required],
    });
  }

  private setEditTime(): void {
    if (!this.day) return;
    // create date with hour and minutes
    const date = new Date();
    date.setHours(this.day.estimatedArrivalTime.hour);
    date.setMinutes(this.day.estimatedArrivalTime.minutes);

    const ISOSDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    this.form.get('time')?.setValue(ISOSDate);
    this.form.get('waitTimeMinutes')?.setValue(String(this.day.extraWaitTimeMinutes));
  }

  getCurrentTime() {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 30);
    const ISOSDate = format(currentDate, "yyyy-MM-dd'T'HH:mm:ss");
    this.form.get('time')?.setValue(ISOSDate);
  }

  setSchedule(): void {
    this.modalCtrl.dismiss(this.form.value, 'save');
  }

  deleteSchedule() {
    this.modalCtrl.dismiss(null, 'delete');
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
