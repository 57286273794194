<ion-content [fullscreen]="false">
  <ion-grid fixed class="ion-no-margin ion-no-padding">
    <ion-row class="ion-no-margin ion-no-padding">
      <ion-col size="12" class="ion-no-margin ion-no-padding" [formGroup]="form">
        <ion-item color="primary" lines="none" [style.paddingTop]="'40px'" [style.paddingBottom]="'10px'">
          <ion-buttons slot="start">
            <ion-button (click)="back()">
              <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-icon src="./assets/icons/route.svg" class="ion-margin-horizontal"></ion-icon>
          <ion-label>
            <h2>{{ currentRoute ? 'Editar' : 'Nueva' }} ruta segura</h2>
          </ion-label>
        </ion-item>

        <!-- new -->
        <ion-col size="12" [formGroup]="form">
          <div class="section-route" [style.paddingTop]="'20px'">
            <div class="section-route__title">
              <span>Ubicación</span>
            </div>

            <div class="section-route__body">
              <div [style.padding]="'0 10px'">
                <div class="input-search">
                  <ion-icon name="search" color="medium" [style.fontSize]="'24px'"></ion-icon>
                  <input type="text" formControlName="address" #search placeholder="Ingresa una ubicación" />
                  <ion-icon
                    [style.fontSize]="'24px'"
                    (click)="clearAddress()"
                    *ngIf="form.get('address')?.valid"
                    name="close-circle-outline"
                  ></ion-icon>
                </div>
              </div>

              <div class="map" [style.marginTop]="'10px'">
                <ion-item color="light" *ngIf="form.get('address')?.valid" lines="none">
                  <ion-icon slot="start" [src]="'./assets/icons/map_pin.svg'"></ion-icon>
                  <ion-label class="ion-text-wrap">
                    <p>{{ form.get('address')?.value }}</p>
                  </ion-label>
                </ion-item>

                <google-map
                  height="30vh"
                  width="100%"
                  [options]="mapOptions"
                  [center]="center"
                  [zoom]="zoom"
                  (mapClick)="moveMap($event)"
                  (mapMousemove)="move($event)"
                >
                  <map-marker [icon]="iconActive" [position]="center"></map-marker>
                  <map-circle [center]="center" [radius]="selectedRadius" [options]="mapCirlceActive"></map-circle>
                </google-map>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col size="12">
          <div class="section-route">
            <div class="section-route__title">
              <span>Tamaño de la zona segura</span>
            </div>

            <div class="section-route__body">
              <ion-item class="range" lines="none">
                <ion-range
                  min="100"
                  max="1000"
                  dualKnobs="false"
                  pin="true"
                  snaps="true"
                  [step]="100"
                  ticks="true"
                  [value]="selectedRadius"
                  color="primary"
                  (ionChange)="changeRadius($event)"
                >
                  <ion-label slot="end">
                    <ion-text color="primary">
                      <b>{{ selectedRadius }} m</b>
                    </ion-text>
                  </ion-label>
                </ion-range>
              </ion-item>
            </div>
          </div>
        </ion-col>

        <ion-col size="12">
          <div class="section-route">
            <div class="section-route__title">
              <span>Ruta</span>
            </div>

            <div class="section-route__body">
              <ion-item color="light">
                <ion-label>
                  <ion-text color="primary">
                    <h3><b>Nombre de la ruta:</b></h3>
                  </ion-text>
                </ion-label>
                <ion-input type="text" formControlName="name" placeholder="ruta 1..."></ion-input>
              </ion-item>
              <ion-row class="ion-justify-content-around ion-margin-top">
                <ion-col size="5">
                  <ion-button
                    [style.width]="'100%'"
                    (click)="changeType('single')"
                    [fill]="selectedType === 'single' ? 'solid' : 'outline'"
                  >
                    <ion-icon slot="start" src="./assets/icons/pin-add-route.svg"></ion-icon>
                    Única vez
                  </ion-button>
                </ion-col>
                <ion-col size="5">
                  <ion-button
                    [style.width]="'100%'"
                    (click)="changeType('recurring')"
                    [fill]="selectedType === 'recurring' ? 'solid' : 'outline'"
                  >
                    <ion-icon slot="start" src="./assets/icons/icon-recurring.svg"></ion-icon>
                    Recurrente
                  </ion-button>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </ion-col>

        <ion-col size="12">
          <div class="ion-no-padding">
            @if (selectedType === 'single') {
            <ion-row class="ion-justify-content-center row-date">
              <ion-col size="auto">
                <ion-datetime
                  locale="es-ES"
                  hourCycle="h11"
                  presentation="time-date"
                  [preferWheel]="false"
                  display-format="YYYY-MM-DDTHH:mm:ssZ"
                  formControlName="schedule"
                  id="fecha-hora"
                >
                  <span slot="time-label">Hora</span>
                </ion-datetime>
              </ion-col>
            </ion-row>

            <ion-item color="light">
              <ion-label>
                <ion-text color="primary">
                  <h3><b>Tiempo de espera</b></h3>
                </ion-text>
              </ion-label>
              <ion-select formControlName="waitTimeMinutes" interface="action-sheet" placeholder="Seleccionar tiempo">
                @for(time of timeList; track time.value) {
                <ion-select-option [value]="time.value">{{ time.name }}</ion-select-option>
                }
              </ion-select>
            </ion-item>
            }
          </div>
        </ion-col>

        @if (selectedType === 'recurring') {
        <ion-col size="12">
          <div class="section-route">
            <div class="section-route__title">
              <span>Selecciona los dias y la hora en la que se repetirá la ruta</span>
            </div>

            <div class="section-route__body">
              <ion-row>
                <ion-col size="12">
                  <ion-list>
                    <ion-item *ngFor="let day of daysofWeek" button (click)="openModal(day)">
                      <ion-label>{{ day.name }}</ion-label>
                      <ion-note slot="end" color="primary" class="note-date">{{
                        day.value | routeDay : scheduleList
                      }}</ion-note>
                    </ion-item>
                  </ion-list>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </ion-col>
        }
        <!-- old -->
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding">
      <ion-col size="12" class="">
        <ion-button
          expand="block"
          size="large"
          color="secondary"
          shape="round"
          (click)="save()"
          [disabled]="form.invalid || form.pristine || isInvalidRecurrence"
        >
          <ion-icon slot="start" name="save"></ion-icon>
          Guardar ruta
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
