import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NutritionRoutingModule } from './nutrition-routing.module';
import { NutritionComponent } from './nutrition.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NutritionComponent],
  imports: [CommonModule, IonicModule, TranslateModule, NutritionRoutingModule],
})
export class NutritionModule {}
