<ion-content no-bounce>
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-justify-content-center ion-no-padding">
      <ion-col size-sm="8" size-lg="6" size-xl="4" class="ion-align-self-center ion-no-padding">
        <div class="ion-text-center logo-container">
          <ion-img src="./assets/logo.png" class="logo"></ion-img>
          <ion-img src="./assets/login.png" class="logo"></ion-img>
        </div>
        <div class="login-container">
          <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
            <ion-text color="danger" [hidden]="!error || loginForm.dirty || isLoading">
              <span class="ion-padding">{{ error }}</span>
            </ion-text>
            <ion-chip
              color="danger"
              [hidden]="loginForm.controls['username'].valid || loginForm.controls['username'].untouched"
            >
              <span class="ion-padding" translate>Email is required</span>
            </ion-chip>
            <ion-item lines="none" class="input-container">
              <!-- <ion-label color="medium" position="fixed" translate>Email</ion-label> -->
              <ion-input
                type="text"
                formControlName="username"
                [placeholder]="'Email' | translate"
                autocomplete="username"
              ></ion-input>
            </ion-item>
            <ion-chip
              color="danger"
              [hidden]="loginForm.controls['password'].valid || loginForm.controls['password'].untouched"
            >
              <span class="ion-padding" translate>Password is required</span>
            </ion-chip>
            <ion-item lines="none" class="input-container">
              <!-- <ion-label color="medium" position="floating" translate>Password</ion-label> -->
              <ion-input
                type="password"
                formControlName="password"
                [placeholder]="'Password' | translate"
                autocomplete="current-password"
              ></ion-input>
            </ion-item>

            <!--
              <ion-item class="item-transparent" lines="none">
                <ion-label translate>Remember me</ion-label>
                <ion-checkbox slot="start" formControlName="remember"></ion-checkbox>
              </ion-item>
            -->

            <ion-button type="submit" expand="block" color="secondary" shape="round" size="large">
              Iniciar Sesión
            </ion-button>

            <div class="ion-text-end">
              <ion-chip color="light" mode="ios" outline="true" (click)="resetPassword()">
                <ion-label>Olvidaste tu contraseña?</ion-label>
              </ion-chip>
            </div>
          </form>
          <hr />
          <!-- <p class="ion-no-margin ion-text-center">
            <ion-text color="dark" translate> o inicia sesión con </ion-text>
          </p> -->
          <!-- <ion-button color="google" expand="block" shape="round" translate (click)="loginWithGoogle()">
            <ion-icon slot="start" name="logo-google"></ion-icon>
            Login with Google
          </ion-button>
          <ion-button color="facebook" expand="block" shape="round" translate>
            <ion-icon slot="start" name="logo-facebook"></ion-icon>
            Login with Facebook
          </ion-button>
          <ion-button color="apple" expand="block" shape="round" translate>
            <ion-icon slot="start" name="logo-apple"></ion-icon>
            Login with Apple
          </ion-button> -->
          <hr />
          <ion-button class="white-button" color="stable" expand="block" shape="round" routerLink="/register" translate>
            Regístrate aquí
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
