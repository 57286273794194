export enum StepRegister {
  PHONE_NUMBER = 0,
  PORTABILIDAD = 1,
  ACTIVATION = 2,
}

export interface NewUser {
  email: string;
  externalId: string;
  projectName: 'mujer-movil';
}
