import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactsService } from '@app/@shared/services/contacts.service';
import { FirestoreService } from '@app/@shared/services/firestore-service.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrl: './invitation.component.scss',
})
export class InvitationComponent implements OnInit {
  idUser!: string;
  accepted: boolean = false;

  constructor(
    private auth: Auth,
    private activRoute: ActivatedRoute,
    private contactService: ContactsService, // private fireService: FirestoreService,
    private toastCtrl: ToastController
  ) {}

  ngOnInit(): void {
    this.getParams();
  }

  private getParams(): void {
    this.activRoute.params.subscribe((params) => {
      this.idUser = params?.['id'];
      console.log(this.idUser);
    });
  }

  async accept() {
    try {
      this.accepted = true;
      console.log(this.idUser);
      const res = await this.contactService.acceptInvitation(this.idUser);
      if (!res.success) {
        this.accepted = false;
        this.toast('Ocurrió un error al aceptar la invitación');
      }
      console.log(res);
    } catch (error) {
      this.accepted = false;
    }
  }

  toast(message: string) {
    this.toastCtrl
      .create({
        message,
        duration: 8000,
        color: 'danger',
      })
      .then((toast) => toast.present());
  }
}
