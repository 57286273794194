import { signal } from '@angular/core';

export const timeList = signal([
  { name: '15 min', value: '15' },
  { name: '30 min', value: '30' },
  { name: '45 min', value: '45' },
  { name: '60 min', value: '60' },
  { name: '1 hora 15 min', value: '75' },
  { name: '1 hora 30 min', value: '90' },
]);
